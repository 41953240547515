<template>
    <div>
        <v-row class="mb-4">
            <template v-if="Object.keys($route.query).length > 0">
                <v-col class="d-flex">
                    <v-btn color="red" size="sm" @click="removeAllFilters">Reinitialiser les filtres</v-btn>
                    <div class="ml-2 align-self-center">
                        <v-chip color="secondary" text-color="black" close @click:close="remove_query(el.key)" class="pl-2" v-for="(el, key) in queryFilter" :key="key">
                            <strong>{{ el.value }}</strong>
                        </v-chip>
                    </div>
                </v-col>
            </template>
        </v-row>
        <v-row class="mb-5 justify-center">
            <v-col cols="auto" v-for="i in 18" :key="i">
                <AppCard
                    title="NOM APPLICATION"
                    category="Categorie"
                    description="Description de l'application, passez la souris sur la carte pour en découvrir un peu plus" 
                    :img_top="'https://picsum.photos/id/' + i * 5 +'/250/200'"
                    :rating_global="4.5"
                    :rating_count="214"
                    :col_display="true"
                    >
                </AppCard>
            </v-col>
        </v-row>
        <div class="text-center mb-9">
            <v-pagination class="mb-4" v-model="page" :length="6" @input="link_page"></v-pagination>
        </div>
    </div>
</template>

<script>
import AppCard from '../../AppCardMax';

export default {
    name : 'Search',
    components : {
        AppCard
    },
    data() {
        return {
            page : parseInt(this.$route.query.page) || 1,
        }
    },
    methods: {
        link_page(pageNum) {
            return this.$router.push({name : 'search', params : { name : this.$route.params.name }, query : Object.assign({}, this.$route.query, { page : pageNum })});
        },
        remove_query(query) {
            let route = this.$route;
            let queryPush = {...route.query};
            delete queryPush[query];
            if(queryPush['page']) delete queryPush['page']; 
            if(Object.keys(queryPush).length === 0) return this.$router.push({name : 'store'});
            else return this.$router.push({ path : '/store/search', query: queryPush })
        },
        removeAllFilters() {
            return this.$router.push({name : 'store'});
        }
    },
    computed : {
        queryFilter : function() {            
            let query = this.$route.query;
            let array = [];
            Object.keys(query).forEach( (val) => {
                return array.push({ key : val, value : query[val] });
            });
            return array.filter((el) => {
                return el.key !== 'page'
            });
        },
    }
}
</script>