<template>
  <div>
    <v-app-bar app dense>
      <v-app-bar-nav-icon
        @click="hideSidebar"
      ></v-app-bar-nav-icon>
      <img to="/" class="py-2" height="100%" src="logo-app-e.svg" />
      <v-spacer></v-spacer>
      <ul>
        <li v-if="!this.$store.state.activeUser">
          <router-link to="/" exact>Accueil</router-link>
        </li>
        <li v-if="this.$store.state.activeUser">
          <router-link to="/dashboard">
            <!-- TABLEAU DE BORD -->
            Tableau de bord
          </router-link>
        </li>
        <li
          v-if="
            this.$store.state.activeUser &&
            this.$store.state.activeUser.userType == 2
          "
        >
          <router-link to="/administration"> Administration </router-link>
        </li>
        <li v-if="this.$store.state.activeUser">
          <router-link to="/library">
            Mes applications
            <!-- BIBLIOTH&Egrave;QUE -->
          </router-link>
        </li>
        <li>
          <router-link to="/store"> Store </router-link>
        </li>
        <li v-if="!this.$store.state.activeUser">
          <router-link to="/pricing"> Solutions </router-link>
        </li>
        <li>
          <router-link to="/forum">
            Communauté
            <!-- COMMUNAUT&Eacute; -->
          </router-link>
        </li>
        <li>
          <v-switch
            class="mt-0"
            :input-value="this.$vuetify.theme.dark"
            @change="
              () => (this.$vuetify.theme.dark = !this.$vuetify.theme.dark)
            "
          ></v-switch>
        </li>
      </ul>

      <v-spacer></v-spacer>

      <template v-if="this.$store.state.activeUser">
        <!-- Messages -->
        <v-menu transition="slide-y-transition" offset-y rounded bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              tile
              v-on="on"
              @click="messagebar = !messagebar"
              class="mr-2"
            >
              <v-badge dot bordered color="red">
                <v-icon>mdi-message-text-outline</v-icon>
              </v-badge>
            </v-btn>
          </template>
        </v-menu>

        <!-- Notifications -->
        <v-menu
          content-class="mt-2"
          max-width="400"
          max-height="600"
          transition="slide-y-transition"
          offset-y
          rounded
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon tile v-on="on" v-bind="attrs" class="mr-2">
              <v-badge dot bordered color="red" :content="notification.length">
                <v-icon>mdi-bell-outline</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <v-list class="py-0" three-line>
            <v-list-item-group>
              <template v-for="(notice, index) in notification">
                <v-divider v-if="index !== 0" :key="index"></v-divider>
                <v-list-item :key="index">
                  <v-list-item-icon>
                    <v-avatar color="primary">JD</v-avatar>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <template v-if="notice.type === 'email'">
                      <v-list-item-title>{{ notice.sender }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        notice.subject
                      }}</v-list-item-subtitle>
                      <v-list-item-content>{{
                        notice.body
                      }}</v-list-item-content>
                    </template>
                    <template v-else-if="notice.type === 'message'">
                      <v-list-item-title>{{ notice.sender }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        notice.body
                      }}</v-list-item-subtitle>
                    </template>
                  </v-list-item-content>
                  <v-list-item-action>
                    <small
                      >Il y a
                      {{
                        Math.floor(10 * (index + 1 + Math.random()))
                      }}
                      min</small
                    >
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <!-- Account -->
        <v-menu
          content-class="mt-2"
          transition="slide-y-transition"
          offset-y
          rounded
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon tile v-on="on" v-bind="attrs" class="mr-2">
              <v-avatar color="nav_main" style="color: white" size="40">{{
                getUserInitial()
              }}</v-avatar>
            </v-btn>
          </template>
          <v-list class="py-0">
            <v-list-item link>
              <v-list-item-title @click="logout">
                Mon compte
              </v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title @click="logout">
                Se déconnecter
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- Settings -->
        <v-menu
          content-class="mt-2"
          transition="slide-y-transition"
          offset-y
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon tile v-on="on" v-bind="attrs" class="mr-4">
              <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
          </template>
          <v-list class="py-0">
            <v-list-item>
              <v-list-item-title>&Agrave; propos</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Aide</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <router-link
                  style="text-decoration: none; color: var(--v-text-base)"
                  to="/settings"
                >
                  Paramètres
                </router-link>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-else>
        <v-btn outlined tile class="mr-2" to="/login"> Se connecter </v-btn>
      </template>
    </v-app-bar>

    <MessageBar
      v-if="this.$store.state.activeUser"
      :show="messagebar"
      @update="(value) => (messagebar = value)"
    />

    <SideBar :show="sidebar" @update="(value) => (sidebar = value)" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import MessageBar from "../Messages";
import SideBar from "../SideBar";

export default {
  name: "Navbar",
  components: {
    MessageBar,
    SideBar,
  },
  data() {
    return {
      messagebar: false,
      sidebar: false,
      notification: [
        {
          id: "1",
          type: "message",
          sender: "Sylverstone STALOOSE",
          body: "Voici un message",
          date: "Il y a 10min",
        },
        {
          id: "2",
          type: "email",
          subject: "Réunion d'information",
          sender: "John DOE",
          body: "Bonjour, La réunion d'information est décalée au jeudi 23 mars.",
          date: "Il y a 2h",
        },
        {
          id: "3",
          type: "email",
          subject: "Réunion d'information",
          sender: "John DOE",
          body: "Bonjour, La réunion d'information est décalée au jeudi 23 mars.",
          date: "Il y a 3h",
        },
        {
          id: "4",
          type: "email",
          subject: "Réunion d'information",
          sender: "John DOE",
          body: "Bonjour, La réunion d'information est décalée au jeudi 23 mars.",
          date: "Il y a 1 jour",
        },
      ],
    };
  },
  methods: {
    ...mapMutations(['removeActiveUser']),
    goToHash(path, hash) {
      if (path && hash && path !== this.$route.path) {
        this.$router
          .push({ path: path })
          .then(() => document.getElementById(hash).scrollIntoView(true));
      } else {
        if (hash) document.getElementById(hash).scrollIntoView(true);
      }
    },
    hideSidebar() {
      console.log("sidebar", this.sidebar);
      this.sidebar = true
      console.log("sidebar", this.sidebar);
    },
    logout() {
      this.removeActiveUser();
      this.$router.push({ path: "/" });
    },
    getUserInitial() {
      return (
        this.$store.getters.activeUser.firstname.charAt(0).toUpperCase() +
        this.$store.getters.activeUser.lastname.charAt(0).toUpperCase()
      );
    },
  },
  updated() {
    var chatbox = document.getElementById("chatbox");
    if (chatbox) chatbox.scrollTo(0, chatbox.scrollHeight);
    
  },
};
</script>

<style lang="scss">
.v-list-item__icon.v-list-group__header__prepend-icon {
  margin-right: 0.5rem !important;
}
.v-list-item__icon.v-list-group__header__append-icon {
  margin: 0 !important;
  min-width: min-content;
}
.v-navigation-drawer__content {
  overflow: hidden !important;
  display: flex !important;
  flex-direction: column !important;
  position: relative !important;
}
</style>

<style lang="scss" scoped>
.v-dialog__content {
  z-index: 4 !important;
}
ul {
  @media screen and (max-width: 600px) {
    display: none;
  }
  overflow: hidden;
  height: 100% !important;
  li {
    position: relative;
    display: inline-block;
    margin-left: 1rem;
    margin-right: 1rem;
    height: 100%;
    a {
      font-size: 16px;
      font-weight: 400;
      color: var(--v-text-base);
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      height: 100%;
      transition: 0.2s linear !important;
      &:after {
        content: "" !important;
        padding-bottom: 0.2rem !important;
        display: block !important;
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 0% !important;
        border-bottom: 1px solid var(--v-nav_main-base) !important;
        -webkit-transition: 0.3s ease-in !important;
        transition: 0.3s ease-in !important;
      }
      &:hover {
        color: var(--v-nav_main-base) !important;
        &:after {
          content: "" !important;
          position: absolute;
          left: 0;
          bottom: 0;
          display: block !important;
          width: 100% !important;
          border-bottom: 1px solid var(--v-nav_main-base) !important;
        }
      }
      &:active {
        color: var(--v-nav_main-base) !important;
        &:after {
          content: "" !important;
          left: 0;
          display: block !important;
          width: inherit !important;
          border-bottom: 1px solid var(--v-nav_main-base) !important;
        }
      }
      &.router-link-active {
        color: var(--v-nav_main-base) !important;
        &:after {
          content: "" !important;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100% !important;
          border-bottom: 1px solid var(--v-nav_main-base) !important;
        }
      }
    }
  }
}
</style>