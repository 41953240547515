<template>
    <v-card>
        <v-card-title class="text-h3 mb-2">Mon compte</v-card-title>
        <v-card-subtitle>Modifiez vos informations personnels et complétez votre profil.</v-card-subtitle>
        <v-card-text>
            <v-row>
                <v-col cols="12" sm="4">
                    <v-avatar color="primary" max-height="100%" max-width="100%" size="200" class="text-h2" style="color:white">JD</v-avatar>
                </v-col>
                <v-col cols="12" sm="8">
                    <div class="pl-5 text--primary">
                        <div class="d-flex mb-0">
                            <v-text-field v-model="account.lastname" label="Nom" placeholder="Nom" class="mr-4"></v-text-field>
                            <v-text-field v-model="account.firstname" label="Prénom" placeholder="Prénom" class="mr-2"></v-text-field>    
                        </div>
                        <v-text-field v-model="account.email" label="Email" placeholder="Email" class="mr-2" disabled></v-text-field>
                        <v-text-field v-model="account.date_birth" label="Date de naissance" placeholder="Date de naissance" class="mr-2"></v-text-field>
                        <v-text-field v-model="account.enterprise" label="Société" placeholder="Société" class="mr-2" disabled></v-text-field>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" :disabled="isChanges()" @click="resetSettings">Annuler</v-btn>
            <v-btn color="success" :disabled="isChanges()" @click="saveSettings">Enregistrer</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name : 'Account',
    data() {
        return {
            account : JSON.parse(localStorage.getItem('settings')).main.account,
            account_backup : JSON.parse(localStorage.getItem('settings')).main.account,
        }
    },
    methods : {
        saveSettings() {
            if (this.account_backup !== this.account) {
                const settings = JSON.parse(localStorage.getItem('settings'));
                settings.main.account = this.account_backup;
                this.account = this.account_backup;
                localStorage.setItem('settings', JSON.stringify(settings));
            }
        },
        resetSettings() {
            this.account = JSON.parse(JSON.stringify(this.account_backup));
        },
        isChanges () {
            if (JSON.stringify(this.account_backup) !== JSON.stringify(this.account)) {
                return false;
            } else {
                return true;
            }
        }
    },
}
</script>