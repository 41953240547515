<template>
    <div class="storeHome-main">
        <div class="storeHome-section mb-5 mx-1">
            <v-card>
                <v-card-title class="text-h6 text-sm-h5 text-md-h4 text-uppercase font-weight-light">
                    &Agrave; la Une
                </v-card-title>
                <WrapperHorizontal>
                    <AppCard
                        v-for="index in 10" :key="index"
                        title="NOM APPLICATION"
                        category="Categorie"
                        description="Description de l'application, passez la souris sur la carte pour en découvrir un peu plus" 
                        :img_top="'https://picsum.photos/id/' + index * 8 +'/250/200'"
                        :rating_global="4.5"
                        :rating_count="214"
                        url="/application/Example"
                        >
                    </AppCard>
                </WrapperHorizontal>
            </v-card>
        </div>
        <div class="storeHome-section mb-5 mx-1">
            <v-card>
                <v-card-title class="text-h6 text-sm-h5 text-md-h4 text-uppercase font-weight-light">
                    Recommandées pour vous
                </v-card-title>
                <WrapperHorizontal>
                    <AppCard
                        v-for="index in 10" :key="index"
                        title="NOM APPLICATION"
                        category="Categorie"
                        description="Description de l'application, passez la souris sur la carte pour en découvrir un peu plus" 
                        :img_top="'https://picsum.photos/id/' + index * 4 +'/250/200'"
                        :rating_global="4.5"
                        :rating_count="214"
                        url="/application/Example"
                        >
                    </AppCard>
                </WrapperHorizontal>
            </v-card>
        </div>
        <div class="storeHome-section mb-5 mx-1">
            <v-card>
                <v-card-title class="text-h6 text-sm-h5 text-md-h4 text-uppercase font-weight-light">
                    Nouveautées
                </v-card-title>
                <WrapperHorizontal>
                    <AppCard
                        v-for="index in 10" :key="index"
                        title="NOM APPLICATION"
                        category="Categorie"
                        description="Description de l'application, passez la souris sur la carte pour en découvrir un peu plus" 
                        :img_top="'https://picsum.photos/id/' + index * 3 +'/250/200'"
                        :rating_global="4.5"
                        :rating_count="214"
                        url="/application/Example"
                        >
                    </AppCard>
                </WrapperHorizontal>
            </v-card>
        </div>
        <div class="storeHome-section mb-5 mx-1">
            <v-card>
                <v-card-title class="text-h6 text-sm-h5 text-md-h4 text-uppercase font-weight-light">
                    Les mieux notées
                </v-card-title>
                <WrapperHorizontal>
                    <AppCard
                        v-for="index in 10" :key="index"
                        title="NOM APPLICATION"
                        category="Categorie"
                        description="Description de l'application, passez la souris sur la carte pour en découvrir un peu plus" 
                        :img_top="'https://picsum.photos/id/' + index * 5 +'/250/200'"
                        :rating_global="4.5"
                        :rating_count="214"
                        url="/application/Example"
                        >
                    </AppCard>
                </WrapperHorizontal>
            </v-card>
        </div>
    </div>
</template>

<script>
import AppCard from '../../AppCardMax';
import WrapperHorizontal from '../../../components/WrapperHorizontal'
export default {
    name : 'StoreHome',
    components : {
        AppCard,
        WrapperHorizontal
    },
    methods : {
        btnShowing(evt) {
            const wrapper = evt.target;
            const btnLeft = wrapper.querySelector('.btn-scroller.left');
            const btnRight = wrapper.querySelector('.btn-scroller.right');
            
            if (wrapper.scrollLeft <= 0) btnLeft.style.opacity = 0;
            else btnLeft.style.opacity = 0.7;
            
            if (wrapper.scrollLeft + wrapper.clientWidth >= wrapper.scrollWidth) btnRight.style.opacity = 0;
            else btnRight.style.opacity = 0.7;
        },
        scrollerX(evt, side) {
            const wrapper = evt.target.closest('.wrapper-cards');
            let scrollValue = 0;
            if (side === 'left') scrollValue = -(wrapper.clientWidth/2);
            else if (side === 'right') scrollValue = wrapper.clientWidth/2;
            console.log(wrapper, side);
            wrapper.scrollBy({ 
                top: 0,
                left: scrollValue, 
                behavior: 'smooth' 
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .storeHome-section {
        position: relative;
        z-index: 2;
        border-radius: $border-radius-root;

        > .v-card {    
            &.theme--dark {
                box-shadow: inset 0px 0px rgba(0, 98, 226, 0.6) !important;
                &:hover {
                    box-shadow: inset 10px 10px 20px -15px rgb(79,0,161, 1) !important;
                }
            }
        }
    }

    *.theme--dark .storeHome-section {
        @include onHoverItemBefore(linear-gradient(195deg, rgb(123, 0, 255) 0%, rgba(255,0,132,1) 100%), 100%, 100%);
    }

    *.theme--light .storeHome-section {
        @include onHoverItemBefore(linear-gradient(195deg, rgb(255, 0, 234) 0%, rgba(255,0,132,1) 100%), 100%, 100%);
    }
</style>