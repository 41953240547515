<template>
    <v-row class="justify-center my-7" style="min-height:calc(100% - 150px)">
        <v-col xl="3" md="4" sm="12">
            <v-card class="pa-5" elevation="4">
                <v-card-title class="display-1">CONNEXION</v-card-title>

                <v-form class="pt-4 h-100" ref="form">

                    <v-text-field v-model="email" prepend-icon="mdi-at" color="nav_main" label="Adresse e-mail" required></v-text-field>
                    <v-text-field v-model="password" prepend-icon="mdi-lock" color="nav_main" label="Mot de passe" required></v-text-field>
                    
                    <v-checkbox color="nav_main" label="Maintenir la connexion"></v-checkbox>
                    
                    <v-row class="justify-center my-2">
                        <v-btn text outlined color="primary" to="#">Mot de passe oublié ?</v-btn>    
                    </v-row>
                    
                    <v-btn type="button" class="my-4" color="primary" @click="login(email, password)" block>CONNEXION</v-btn>
                    <v-btn to="/register" color="secondary" style="color:black" block>INSCRIPTION</v-btn>
                </v-form>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    name : 'Login',
    title: 'APP-e | Connexion',
    data() {
        return {
            email : null,
            password : null,
        }
    },
    methods : {
        ...mapMutations(['setConnectedUser']),
        login(email, password) {
            if (email && password) {
               this.setConnectedUser(email);
               this.$router.push({ path : '/dashboard'});
            }
        }
    }
}
</script>