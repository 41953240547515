import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const primary = "#6f00f2";
const secondary = "#dea934";

export default new Vuetify({
    theme: { 
        dark: true,
        themes : {
            light : {
                // primary : "#3469de",
                // secondary : "#dea934", 
                primary : primary,
                secondary : "#dea934",
                text : "#000000",
                nav_main : primary,
                bg_card_app : "#ffffff",
                scrollbar_active : primary,
                scrollbar_thumb : "#ababab",
                scrollbar_track : "#919191",
                overlay : "#999999",
                overlay_card : "#fefefe"
            },
            dark : {
                theme : "#123456",
                primary : primary,
                secondary : "#dea934",
                text : "#ffffff",
                nav_main : secondary,
                bg_card_app : "#495664",
                scrollbar_active : "#9c00da",
                scrollbar_thumb : "#4c2c67",
                scrollbar_track : "#0d1114",
                overlay : "#000000",
                overlay_card : "#5e5e5e",
                messageBarBg : "#1b1c1d"
            }
        },
        icons: {
            iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
        },
        options: { customProperties: true },
    },
});
