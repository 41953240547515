<template>
    <v-navigation-drawer v-model="show" fixed right width="450">
        <v-app-bar dense>
            <v-tabs v-model="messageTab" color="secondary">
                <v-tab>
                    <v-icon>mdi-message-text-outline</v-icon><span class="pl-1">Chat</span>
                </v-tab>
                <v-tab>
                    <v-icon>mdi-account-group-outline</v-icon><span class="pl-1">Contacts</span>
                </v-tab>
            </v-tabs>   
            <v-spacer></v-spacer>
            <v-btn icon color="nav_main" @click="closeBar">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-app-bar>
        <v-tabs-items class="caption" v-model="messageTab" style="height:100%;background-color:var(--v-messageBarBg-base)">
            <v-tab-item style="height:100%;">
                <template v-if="convers">
                    <v-toolbar class="ma-0" outlined>
                        <v-btn icon class="mr-1" @click="() => convers = false">
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                        <v-avatar dark size="50" class="mr-1" style="color:white" color="primary">NU</v-avatar>
                        <div class="text-h5 font-weight-light mx-2">Nom Utilisateur</div>
                        <v-spacer></v-spacer>
                        <v-avatar size="10" color="#51E646FF"></v-avatar>
                    </v-toolbar>
                    <div id="chatbox" class="wrapper" style="height:calc(100% - 300px) !important;overflow-x:hidden">
                        <v-row class="my-1" v-for="i in 20" :key="i">
                            <template v-if="Number.isInteger(i/3)">
                            <v-col cols="auto" class="px-5 py-3 d-flex align-center" style="width:100%">
                                <v-avatar size="40" class="mr-1" style="color:white" color="primary">NU</v-avatar>
                                <v-card class="rounded-xl" color="#1C88F0FF" width="max-content" max-width="90%">
                                    <v-card-text class="body-2" style="color:white">Message {{ i }}</v-card-text>
                                </v-card>
                                <small class="text--secondary" v-if="i === 20">Lu à 15h02</small>
                            </v-col>
                            </template>
                            <template v-else-if="Number.isInteger(i/4) && i !== 20">
                            <v-col cols="auto" class="px-5 py-3" style="width:100%;text-align:center">
                                <v-divider class="mb-4"></v-divider>
                                <small class="text--primary">Mardi {{ i }} Janvier</small>
                            </v-col>
                            </template>
                            <template v-else>
                            <v-col cols="auto" style="width:100%" class="px-5 py-3">
                                <v-card class="rounded-xl float-right" color="#00BB16FF" max-width="90%">
                                    <v-card-text class="body-2" style="color:white">Message n°{{ i }}</v-card-text>
                                </v-card>
                            </v-col>
                            </template>
                        </v-row>
                    </div>
                    <v-divider></v-divider>
                    <v-sheet class="px-2" color="messageBarBg" style="z-index:4;position:relative;width:100%;height:250px">
                        <v-btn icon large><v-icon>mdi-image-plus</v-icon></v-btn>
                        <v-btn icon large><v-icon>mdi-gif</v-icon></v-btn>
                        <v-textarea
                            no-resize
                            rows="3"
                            clearable
                            label="Message"
                            placeholder="Écrivez un message ..."
                            outlined
                        ></v-textarea>
                        <v-btn class="rounded-xl" block width="50%" color="primary">Envoyer</v-btn>
                    </v-sheet>
                </template>
                <template v-else>
                    <v-list class="py-0">
                        <v-list-item-group>
                            <template v-for="i in 6">
                                <v-list-item :key="'ddA-' + i" @click="() => convers = true">
                                    <v-list-item-action class="text--secondary">
                                        <v-avatar style="color:white" color="primary">NU</v-avatar>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6 font-weight-light">
                                            Nom Utilisateur
                                            <v-avatar class="mr-1" size="10" :color="Number.isInteger(i/2) ? '#51E646FF' : 'red'"></v-avatar>
                                        </v-list-item-title>
                                        <div class="caption text--disabled">Aperçu du dernier message</div>
                                    </v-list-item-content>
                                    <v-list-item-action class="text--secondary">
                                        <small>Il y a {{ Math.floor(Math.random() * Math.floor(60))}} min</small>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </template>
            </v-tab-item>
            <v-tab-item>
                <v-list class="pt-0">
                    <v-list-item-group>
                        <template v-for="i in 6">
                            <v-list-item :key="'A-' + i">
                                <v-list-item-action class="text--secondary">
                                    <v-avatar color="primary">NU</v-avatar>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h6 font-weight-light">Nom Utilisateur</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action class="text--secondary">
                                    <small>Il y a {{ Math.floor(Math.random() * Math.floor(60))}} min</small>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                    </v-list-item-group>
                </v-list>
            </v-tab-item>
        </v-tabs-items>
    </v-navigation-drawer>
</template>

<script>
export default {
    name : 'Message',
    props : {
        show : {
            type : Boolean,
            default : false
        }
    },
    data() {
        return {
            convers : false,
            messageTab : null,
        }
    },
    methods: {
        closeBar() {
            this.$emit('update', false);
        }
    },
}
</script>

<style lang="scss" scoped>

</style>