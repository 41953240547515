const settings = {
        preferencies : {
            theme : true,
            widgets : [
                { id : 10, name : 'Search', size : 'md', sizeAvailable : ['md'] },
                { id : 4, name : 'Trello', size : 'md', sizeAvailable : ['xs', 'md', 'xl', 'xmd'] },
                { id : 1, name : 'Gmail', size : 'md', sizeAvailable : ['sm', 'md', 'lg', 'xl'] },
                { id : 6, name : 'Prestashop', size : 'xs', sizeAvailable : ['xs', 'sm'] },
                { id : 7, name : 'Prestashop', size : 'sm', sizeAvailable : ['xs', 'sm'] },
                { id : 2, name : 'GoogleDrive', size : 'sm', sizeAvailable : ['sm', 'md'] },
                { id : 3, name : 'GoogleAgenda', size : 'sm', sizeAvailable : ['sm', 'md', 'xl', 'xmd'] },
                { id : 8, name : 'GoogleHangout', size : 'sm', sizeAvailable : ['sm', 'md'] },
                { id : 5, name : 'Zendesk', size : 'xs', sizeAvailable : ['xs'] },
            ],
            sync_mail : [
                { name : 'Google : johndoe@gmail.com', enable : true },
                { name : 'Outlook : johndoe@hotmail.com', enable : true }
            ],
            sync_calendar : [
                { name : 'Google Agenda', enable : true },
                { name : 'Outlook Calendar', enable : true }
            ],
            notifications : {
                mails : true,
                sms : false
            },
            start_page : {
                selected_page : 1,
                available_page : [
                    { id : 1, name : 'Page d\'accueil', url : '/'},
                    { id : 2, name : 'Tableau de bord', url : '/dashboard'},
                ]
            }
        },
        main : {
            account : {
                firstname : 'John',
                lastname : 'DOE',
                email : 'johndoe@mail.com',
                date_birth : '01/01/1989',
                type : 1,
                enterprise : 'Ma société',
                avatar  : '',
            },
            subscriptions : {
                newsletters : false,
                item_sub : [
                    { logo : 'img', nom_abonnement: 'G-Suite', nombre_users : '5', montant_mensuel : '100€', montant_annuel : '1200€', state : 'Actif' },
                    { logo : 'img', nom_abonnement: 'Microsoft Office 2020', nombre_users : '5', montant_mensuel : '100€', montant_annuel : '1200€', state : 'Suspendu' },
                    { logo : 'img', nom_abonnement: 'Adobe Créative Cloud', nombre_users : '5', montant_mensuel : '100€', montant_annuel : '1200€', state : 'Actif' },
                    { logo : 'img', nom_abonnement: 'Amazon Prime', nombre_users : '5', montant_mensuel : '100€', montant_annuel : '1200€', state : 'Erreur de paiement' },
                    { logo : 'img', nom_abonnement: 'Quickbooks Essentials', nombre_users : '5', montant_mensuel : '125€', montant_annuel : '1500€', state : 'Actif' },
                    { logo : 'img', nom_abonnement: 'Salesforces', nombre_users : '5', montant_mensuel : '125€', montant_annuel : '1500€', state : 'Actif' },
                    { logo : 'img', nom_abonnement: 'EasyCron premium', nombre_users : '5', montant_mensuel : '125€', montant_annuel : '1500€', state : 'Actif' },
                    { logo : 'img', nom_abonnement: 'Linkedin', nombre_users : '5', montant_mensuel : '125€', montant_annuel : '1500€', state : 'Actif' },
                    { logo : 'img', nom_abonnement: 'Slack Enterprise Grid', nombre_users : '5', montant_mensuel : '125€', montant_annuel : '1500€', state : 'Actif' },
                ]
            },
            factures : true,
            payment : [
                { type : 'MasterCard', user_name : 'John DOE', number_card : '**** **** **** 5290', expiration_date : '07/25', enable : true },
                { type : 'Visa', user_name : 'John DOE', number_card : '**** **** **** 2693', expiration_date : '07/25', enable : false }
            ],
            personal_history : [
                { date_connexion : 'Il y a 1 heure', ip_adress : '172.16.32.17', computer_name : 'My_machine' },
                { date_connexion : 'Il y a 12 heure', ip_adress : '172.16.32.17', computer_name : 'My_machine' },
                { date_connexion : 'Il y a 1 jour', ip_adress : '172.16.32.17', computer_name : 'My_machine' },
                { date_connexion : 'Il y a 2 jours', ip_adress : '172.16.32.17', computer_name : 'My_machine' },
                { date_connexion : 'Il y a 4 jours', ip_adress : '172.16.32.17', computer_name : 'My_machine' },
            ]
        },
        security : {
            authorized_ip : [
                { adress : '172.16.32.17', enable : true },
                { adress : '172.16.32.13', enable : false },
                { adress : '172.16.32.14', enable : true },
            ]
        }
    };

export default settings;