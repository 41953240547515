import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import settings from '../settings'

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        // Variables
        users : [
            { id : 1, firstname : 'John', lastname : 'DOE', email : 'freelance@mail.com', userType : 1},
            { id : 2, firstname : 'John', lastname : 'DOE', email : 'manager@mail.com', userType : 2},
            { id : 3, firstname : 'John', lastname : 'DOE', email : 'user@mail.com', userType : 3},
            { id : 3, firstname : 'John', lastname : 'DOE', email : 'root', userType : 2},
        ],
        activeUser : null,
        settings 
    },
    getters: {
        users: state => state.users,
        activeUser: state => state.activeUser,
        settings: state => state.settings,
    },
    mutations: {
        //  Setters
        setConnectedUser(state, email) {
            state.users.forEach(user => {
                if (email === user.email) {
                    return state.activeUser = user;
                }
            });
        },
        setSettings(state, modifiedSettings) {
            if (JSON.stringify(state.settings) !== JSON.stringify(modifiedSettings)) {
                state.settings = modifiedSettings
            }
        },
        removeActiveUser(state) {
            if (state.activeUser) {
                return state.activeUser = null
            }
        }
    }
});