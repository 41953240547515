import Vue from 'vue'
import App from './App.vue'
import VueCookies from 'vue-cookies'
import router from './router'
import pageTitle from './mixins/pageTitle'
import vuetify from './plugins/vuetify';
import store from './store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCog, faUserCircle, faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

Vue.use(VueCookies);
Vue.mixin(pageTitle)

library.add(faCog, faUserCircle, faBell);

Vue.component('font-awesome-icon', FontAwesomeIcon);



//Config
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  vuetify,
  router,
  store
}).$mount('#app')