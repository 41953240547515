<template>
    <v-row justify="center" align="center" style="height:90%">
        <div style="text-align:center;">
            <div class="text-h2 font-weight-light my-15">Mince ! <br> La page recherchée n'existe pas...</div>
            <v-btn @click="$router.go('-1')">
                <v-icon class="mr-2">mdi-arrow-left</v-icon> 
                Revenir à la page précédente
            </v-btn>
        </div>
    </v-row>
</template>

<script>
export default {
    name : 'Register',
    title: 'APP-e | Page Introuvable',
}
</script>
