<template>
    <v-row class="justify-center my-7" style="min-height:calc(100% - 150px)">
        <v-col xl="3" md="4" sm="12">
            <v-card class="pa-5" elevation="4">
                <v-card-title class="display-1">INSCRIPTION</v-card-title>
                <v-form class="pt-4 h-100" ref="form">

                    <v-row>
                        <v-col cols="6">
                            <v-text-field prepend-icon="mdi-account" color="nav_main" label="Nom" required></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field color="nav_main" label="Prénom" required></v-text-field>
                        </v-col>
                    </v-row>

                    <v-text-field prepend-icon="mdi-at" color="nav_main" label="Adresse e-mail" required></v-text-field>
                    
                    <v-text-field prepend-icon="mdi-lock" color="nav_main" label="Mot de passe" required></v-text-field>
                    <v-text-field prepend-icon="mdi-lock" color="nav_main" label="Confirmer le mot de passe" required></v-text-field>

                    <v-row class="justify-center my-2">
                        <v-btn text outlined color="primary" to="/login">Déjà inscrit ?</v-btn>    
                    </v-row>
                    
                    <v-btn type="submit" class="my-3" color="primary" block>S'INSCRIRE</v-btn>
                    
                </v-form>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name : 'Register',
    title: 'APP-e | Inscription',
}
</script>