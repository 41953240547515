<template>
    <v-container fluid class="container">
        <v-card class="rounded pa-2">
            <div class="pa-3">
                <div class="text-md-h3 text-sm-h4 text-h5 mb-3">Communauté</div>
                <div class="text-subtitle-1 font-weight-light mb-6">Vous avez des questions ou vous cherchez de l'aide ? La communauté est là pour vous aider !</div>
                <v-divider class="mb-6"></v-divider>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            append-icon="mdi-magnify" 
                            label="Rechercher" 
                            class="pa-0 my-2 mx-6 body-2"
                            color="primary"
                            single-line hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-select
                            :items="['Administration', 'Comptabilité', 'Média', 'Business Intelligent']"
                            label="Catégorie"
                            dense
                            solo
                            color="primary"
                            background-color="primary"
                            item-color="primary"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-btn class="ma-0" color="primary"><v-icon>mdi-plus</v-icon>Créer un sujet</v-btn>
            </div>
            <div>
                <div class="text-md-h3 text-sm-h4 text-h5 font-weight-light pa-3">Les sujets de la semaine</div>
                <v-list>
                    <v-divider></v-divider>
                    <template v-for="i in 10">
                        <v-list-item three-line :key="i + 200" link @click="$router.push({ name : 'Post'})">
                            <v-list-item-avatar tile size="90" color="grey" :key="i+50"></v-list-item-avatar>
                            <v-list-item-content>
                                <v-row>
                                    <v-col cols="12" sm="3">
                                        <div class="overline mr-3">Application name</div>
                                        <div class="caption text--secondary mr-5">Il y a 1h</div>
                                        <v-chip color="success my-1 mr-3" small>Résolu</v-chip>
                                        <div class="caption">32 réponses</div>
                                    </v-col>
                                    <v-col cols="12" sm="9" align="start">
                                        <div class="text-h4 font-weight-light">Titre du sujet</div>
                                        <div class="caption text--secondary">Description du sujet</div>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider :key="i+100"></v-divider>
                    </template>
                </v-list>
                <div class="text-center mb-9">
                    <v-pagination class="mb-4 font-weight-light" elevation="15" v-model="page" :length="6"></v-pagination>
                </div>
            </div>
        </v-card>
    </v-container>
</template>
<script>
export default {
    name : 'Community',
    title : 'APP-e | Communauté',
    data() {
        return {
            page : 1
        }
    },
}
</script>

<style lang="scss" scoped>
    @media screen and (min-width: 960px) {
        .container {
            width: 70% !important;
        }
    }
</style>