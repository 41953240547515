<template>
    <div>
        <div class="storeSearchArea">
            <v-card class="mb-4" elevation="10">
                <v-row class="mt-2">
                    <v-col cols="12" sm="3" md="2" lg="2"><v-toolbar-title class="ml-3 text-h4 font-weight-light">STORE</v-toolbar-title></v-col>
                    <v-col cols="12" sm="9" md="5" lg="6">
                        <v-text-field
                            v-model="search"
                            v-on:keyup.enter="() => { 
                                if(search && $route.query.research !== search) $router.push({ path: '/store/search', query: { research : search, page : 1 }})
                            }" 
                            @click:append="() => {
                                if(search && $route.query.research !== search) $router.push({ path: '/store/search', query: { research : search, page : 1 }})
                            }"
                            class="px-4"
                            dense
                            append-icon="mdi-magnify" 
                            label="Rechercher" 
                            single-line 
                            hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="2">   
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-badge color="red" dot class="mx-2">
                                    <v-btn class="overline" color="primary" v-bind="attrs" v-on="on">
                                        <v-icon class="mr-1">mdi-inbox-full</v-icon>
                                        Liste de souhaits
                                    </v-btn>
                                </v-badge>
                            </template>
                            <v-list class="mt-1 py-0" dense color="primary">
                                <v-list-item v-for="i in 5" :key="i" link>
                                    <v-list-item-title>application : {{ i }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="2" lg="2">
                        <v-badge color="red" dot class="mx-2">
                            <v-btn class="overline" color="primary">
                                <v-icon class="mr-1">mdi-cart-check</v-icon>
                                Panier
                            </v-btn>
                        </v-badge>
                    </v-col>
                </v-row>
                <v-row align="baseline">
                    <v-col cols="12" sm="3">
                        <v-btn class="ml-3 mb-1"  text @click="() => show_categories = !show_categories">
                            <v-icon class="mr-1">mdi-view-grid-outline</v-icon>
                            Catégories
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="9" class="pa-0">
                        <WrapperHorizontal :showBtn="false">
                            <ul class="pa-0">
                                <li>
                                    <router-link class="nav-link" to="/store" exact>
                                        Accueil
                                    </router-link>
                                </li>
                                <li>
                                    <router-link class="nav-link" to="/store/offers">
                                        Offres
                                    </router-link>
                                </li>
                                <li>
                                    <router-link class="nav-link" to="/forum">
                                        Forum
                                    </router-link>
                                </li>
                                <li>
                                    <router-link class="nav-link" to="/forum">
                                        Suggestions
                                    </router-link>
                                </li>
                            </ul>    
                        </WrapperHorizontal>
                    </v-col>
                </v-row>
            </v-card>
        </div>
        <v-navigation-drawer v-model="show_categories" style="z-index:5"  fixed temporary right>
            <v-list>
                <v-list-item-content class="py-1">
                    <v-list-item-title class="title ml-3 text-uppercase font-weight-light d-flex align-center">
                        Catégories
                        <v-btn color="primary" icon class="ml-auto mr-2" @click="() => show_categories = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-list-item-title>
                </v-list-item-content>
                <template v-for="(categorie, key) in categories">
                    <v-list-item :key="'A' + key" link @click="$router.push({ name : 'category', params : { name : categorie.name }, query : { page : 1} }), show_categories = !show_categories, search = null">
                        <v-list-item-title>{{ categorie.name }}</v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>
        <router-view class="pt-4"></router-view>
    </div>
</template>

<script>
import WrapperHorizontal from "../../components/WrapperHorizontal.vue";
export default {
    name : 'Store',
    title: 'APP-e | Store',
    components: {
        WrapperHorizontal
    },
    data() {
      return {
        show_categories : false,
        search : '',
        tags: [],
        categories : [
            { id : '1', name : 'Administration' },
            { id : '2', name : 'Bureautique' },
            { id : '3', name : 'Comptabilité' },
            { id : '4', name : 'Réseau social' },
            { id : '5', name : 'Business intelligent' },
            { id : '6', name : 'Photo' },
            { id : '7', name : 'Vidéo' },
            { id : '8', name : 'Messagerie' },
            { id : '9', name : 'Gestion de projet' },
            { id : '10', name : 'Calendrier' },
            { id : '11', name : 'Graphismes' },
        ]
      }
    },
    updated() {
        document.getElementById('main-app').scrollTop = 0;
    },
}
</script>

<style lang="scss" scoped>

    .storeSearchArea {
        position:sticky;
        top: 0.5rem;
        z-index: 3;
        border-radius: $border-radius-root;

        @media screen and (max-width:600px) {
            position: relative;
        }

        .v-card {
            position: relative;    
            transition: 0.3s ease-in-out !important;
            overflow: hidden;

            &.theme--dark {
                box-shadow: inset 0 0 rgb(79,0,161, 1) !important;
                &:hover {
                    box-shadow: inset 10px 10px 20px -15px rgb(79,0,161, 1) !important;
                }
            }
        }
    }

    *.theme--dark .storeSearchArea  {
        @include onHoverItemBefore(linear-gradient(195deg, rgba(79,0,161,1) 0%, rgba(255,0,132,1) 100%), calc(100% + 4px), calc(100% + 4px));
    }

    *.theme--light .storeSearchArea  {
        @include onHoverItemBefore(linear-gradient(195deg, rgb(255, 0, 234) 0%, rgba(255,0,132,1) 100%), calc(100% + 4px), calc(100% + 4px));
    }

    ul { 
        height: inherit;
        li {
            position: relative;
            display: inline-block;
            margin-left: 1rem;
            margin-right: 1rem;
            height: 100%;
            a {
                font-weight: 400;
                color : var(--v-text-base);
                text-decoration: none;     
                display: inline-flex; 
                align-items: center;
                height: 100%;
                transition: 0.1s linear !important;
                &:after {
                    content: "" !important;
                    padding-bottom: 0.2rem !important;
                    display: block !important;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    width: 0% !important;
                    border-bottom: 1px solid var(--v-nav_main-base) !important;
                    transition: 0.3s ease-in !important;
                }
                &:hover {
                    color: var(--v-nav_main-base) !important;
                    &:after {
                        content: "" !important;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        display: block !important;
                        width: 100% !important;
                        border-bottom: 1px solid var(--v-nav_main-base) !important; 
                    }
                }
                &:active {
                    color: var(--v-nav_main-base) !important;
                    &:after {
                        content: "" !important;
                        left: 0;
                        display: block !important;
                        width: inherit !important;
                        border-bottom: 1px solid var(--v-nav_main-base) !important; 
                    }
                }
                &.router-link-active {
                    color: var(--v-nav_main-base) !important;
                    &:after {
                        content: "" !important;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100% !important;
                        border-bottom: 1px solid var(--v-nav_main-base) !important; 
                    }
                }
            }
        }
    }
</style>