<template>
    <div style="height:90px">
            <v-divider></v-divider>
            <v-row class="my-4" justify="center">
                <v-col cols="12" sm="auto" align="center"><router-link to="#">&Agrave; propos</router-link></v-col>
                <v-col cols="12" sm="auto" align="center"><router-link to="#">Conditions d'utilisations</router-link></v-col>
                <v-col cols="12" sm="auto" align="center"><router-link to="#">Confidentialité</router-link></v-col>
                <v-col cols="12" sm="auto" align="center"><router-link to="#">Forum</router-link></v-col>
                <v-col cols="12" sm="auto" align="center"><router-link to="#">Aide</router-link></v-col>
            </v-row>
            <v-row class="mb-4" align="center">
                <v-col cols="12" sm="12" class="d-flex justify-center">
                    <img height="25" class="mr-2" src="logo-app-e.svg">
                    <div class="p-2 text--disabled">© 2020 App-enterprise. Tous droits réservés. Les marques citées sont la propriété de leurs détenteurs respectifs.</div>
                </v-col>
            </v-row>
    </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>
