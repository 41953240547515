<template>
    <div class="app-card">
        <v-card :class="' ' + (deploy_hover ? ' card-oversize ' : '') + (col_display ? ' col-card ' : '')" :to="url || '#'" elevation="4">
            <div class="app-card-img">
                <img :src="img_top">
            </div>
            <div class="app-card-content px-4">
                <div class="text-h6 font-weight-light">Title</div>
                <v-row class="ma-0">
                    <v-col cols="8" class="px-0">
                        <v-progress-linear rounded color="yellow" value="85"></v-progress-linear>
                    </v-col>
                    <v-col class="pa-0">
                        <span class="ml-2 grey--text caption d-inline-block">{{ `${rating_global} (${rating_count})` }}</span>
                    </v-col>
                </v-row>
                <div>Catégorie</div>
                <div>Description courte</div>
                <!-- <v-card-title class="px-4 pb-0 font-weight-light" v-if="title">{{ title }}</v-card-title>    
                <v-card-actions class="px-4 py-0" v-if="rating_count && rating_global">
                    <v-rating :value="rating_global" background-color="secondary" color="secondary" size="20" dense half-increments readonly></v-rating>
                    <span class="grey--text caption ml-2">{{ `${rating_global} (${rating_count})` }}</span>
                </v-card-actions>
                <v-card-subtitle class="pb-0 overline" v-if="category">{{ category }}</v-card-subtitle>
                <v-card-text class="text-body-2" v-if="description">{{ description }}</v-card-text> -->
            </div>
        </v-card>
    </div>
</template>

<script>
export default {
    name : 'AppCard',
    props : {
        url : String,
        img_top : String,
        title : String,
        description : String,
        category : String,
        rating_count : Number,
        rating_global : Number,
        deploy_hover : Boolean,
        margin : String,
        col_display : Boolean,
    }
}
</script>

<style lang="scss" scoped>
    .app-card {
        position: relative;
        display : inline-block;
        margin: 0.75rem; 
        width:17rem !important;
        height:22rem !important;
        max-height: inherit;
        z-index: 1;
        transition: 0.3s ease-in-out;
        border-radius: $border-radius-root;
        
        > .v-card {
            max-height: inherit;
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            box-shadow: inset 0px 0px rgba(0, 98, 226, 0.6) !important;

            &.theme--dark {
                background: #314754;
            }
            
            &.theme--light {
                color: white;
                background: #978639;
            }

            .app-card-img {
                border-radius: inherit;
                position: relative;
                height: 14rem;
                > img {
                    position: absolute;
                    width: 15rem;
                    height: 12rem;
                    top: 1rem;
                    left: 1rem;
                    border-radius: inherit;
                }
            }

            &.theme--dark {
                &:hover {
                    box-shadow: inset 10px 10px 20px -15px rgba(248, 211, 0, 0.733) !important;
                }
            }
            
            &.theme--light {
                &:hover {
                    box-shadow: inset 10px 10px 10px -10px rgba(255, 251, 0, 0.5) !important;
                }
            }
        }
        
        +.col-card {
            margin: 0 !important;
            width : 300px !important;
            height: 400px !important;
        }
    }
    
    *.theme--dark .app-card  {
        // @include onHoverItemBefore(linear-gradient(312deg, rgba(49,71,84,1) 0%, rgb(80, 237, 240) 100%), 100%, 100%);
        @include onHoverItemBefore(linear-gradient(312deg, rgb(149, 142, 4) 0%, rgb(255, 217, 0) 100%), 100%, 100%);
    }

    *.theme--light .app-card  {
        box-shadow:0 0 15px -10px black;
        @include onHoverItemBefore(linear-gradient(132deg, #ffe600 0%, #b18905 70%), 100%, 100%);
    }
</style>