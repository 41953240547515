var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"app":"","dense":""}},[_c('v-app-bar-nav-icon',{on:{"click":_vm.hideSidebar}}),_c('img',{staticClass:"py-2",attrs:{"to":"/","height":"100%","src":"logo-app-e.svg"}}),_c('v-spacer'),_c('ul',[(!this.$store.state.activeUser)?_c('li',[_c('router-link',{attrs:{"to":"/","exact":""}},[_vm._v("Accueil")])],1):_vm._e(),(this.$store.state.activeUser)?_c('li',[_c('router-link',{attrs:{"to":"/dashboard"}},[_vm._v(" Tableau de bord ")])],1):_vm._e(),(
          this.$store.state.activeUser &&
          this.$store.state.activeUser.userType == 2
        )?_c('li',[_c('router-link',{attrs:{"to":"/administration"}},[_vm._v(" Administration ")])],1):_vm._e(),(this.$store.state.activeUser)?_c('li',[_c('router-link',{attrs:{"to":"/library"}},[_vm._v(" Mes applications ")])],1):_vm._e(),_c('li',[_c('router-link',{attrs:{"to":"/store"}},[_vm._v(" Store ")])],1),(!this.$store.state.activeUser)?_c('li',[_c('router-link',{attrs:{"to":"/pricing"}},[_vm._v(" Solutions ")])],1):_vm._e(),_c('li',[_c('router-link',{attrs:{"to":"/forum"}},[_vm._v(" Communauté ")])],1),_c('li',[_c('v-switch',{staticClass:"mt-0",attrs:{"input-value":this.$vuetify.theme.dark},on:{"change":function () { return (this$1.$vuetify.theme.dark = !this$1.$vuetify.theme.dark); }}})],1)]),_c('v-spacer'),(this.$store.state.activeUser)?[_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":"","rounded":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","tile":""},on:{"click":function($event){_vm.messagebar = !_vm.messagebar}}},on),[_c('v-badge',{attrs:{"dot":"","bordered":"","color":"red"}},[_c('v-icon',[_vm._v("mdi-message-text-outline")])],1)],1)]}}],null,false,2782207779)}),_c('v-menu',{attrs:{"content-class":"mt-2","max-width":"400","max-height":"600","transition":"slide-y-transition","offset-y":"","rounded":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","tile":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"dot":"","bordered":"","color":"red","content":_vm.notification.length}},[_c('v-icon',[_vm._v("mdi-bell-outline")])],1)],1)]}}],null,false,1998819241)},[_c('v-list',{staticClass:"py-0",attrs:{"three-line":""}},[_c('v-list-item-group',[_vm._l((_vm.notification),function(notice,index){return [(index !== 0)?_c('v-divider',{key:index}):_vm._e(),_c('v-list-item',{key:index},[_c('v-list-item-icon',[_c('v-avatar',{attrs:{"color":"primary"}},[_vm._v("JD")])],1),_c('v-list-item-content',[(notice.type === 'email')?[_c('v-list-item-title',[_vm._v(_vm._s(notice.sender))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(notice.subject))]),_c('v-list-item-content',[_vm._v(_vm._s(notice.body))])]:(notice.type === 'message')?[_c('v-list-item-title',[_vm._v(_vm._s(notice.sender))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(notice.body))])]:_vm._e()],2),_c('v-list-item-action',[_c('small',[_vm._v("Il y a "+_vm._s(Math.floor(10 * (index + 1 + Math.random())))+" min")])])],1)]})],2)],1)],1),_c('v-menu',{attrs:{"content-class":"mt-2","transition":"slide-y-transition","offset-y":"","rounded":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","tile":""}},'v-btn',attrs,false),on),[_c('v-avatar',{staticStyle:{"color":"white"},attrs:{"color":"nav_main","size":"40"}},[_vm._v(_vm._s(_vm.getUserInitial()))])],1)]}}],null,false,287031185)},[_c('v-list',{staticClass:"py-0"},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":_vm.logout}},[_vm._v(" Mon compte ")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":_vm.logout}},[_vm._v(" Se déconnecter ")])],1)],1)],1),_c('v-menu',{attrs:{"content-class":"mt-2","transition":"slide-y-transition","offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"icon":"","tile":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cog-outline")])],1)]}}],null,false,3074970311)},[_c('v-list',{staticClass:"py-0"},[_c('v-list-item',[_c('v-list-item-title',[_vm._v("À propos")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v("Aide")])],1),_c('v-list-item',[_c('v-list-item-title',[_c('router-link',{staticStyle:{"text-decoration":"none","color":"var(--v-text-base)"},attrs:{"to":"/settings"}},[_vm._v(" Paramètres ")])],1)],1)],1)],1)]:[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","tile":"","to":"/login"}},[_vm._v(" Se connecter ")])]],2),(this.$store.state.activeUser)?_c('MessageBar',{attrs:{"show":_vm.messagebar},on:{"update":function (value) { return (_vm.messagebar = value); }}}):_vm._e(),_c('SideBar',{attrs:{"show":_vm.sidebar},on:{"update":function (value) { return (_vm.sidebar = value); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }