<template>
  <v-app style="height:100% !important">
    <Navbar />
    <v-main id="main-app" class="wrapper">
      <v-container style="height:100% !important" fluid>
        <router-view/>
        <Footer />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from './components/includes/Navbar';
import Footer from './components/includes/Footer';

export default {
  name: 'App',
  components : {
    Navbar,
    Footer,
  },
};
</script>

<style lang="scss">
.wrapper {
  height: 100% !important;
  overflow-x: hidden;
  overflow-y: auto;
}
.wrapper-card {
  height: calc(100% - 70px) !important;
  overflow-x: hidden;
  overflow-y: auto;
}

::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}
::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}
::-webkit-scrollbar-thumb {
	background: var(--v-scrollbar_thumb-base);
	border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover{
  background: var(--v-scrollbar_active-base);
}
::-webkit-scrollbar-track{
  border-radius: 10px;
  height: 100px;
	background: var(--v-scrollbar_track-base);
}

.animated {
  border : 2px solid transparent;
  border-radius : 5px;
  -moz-border-radius: 5px;
  background-image:
    linear-gradient(150deg,  rgb(33, 0, 180) 5%, rgb(51, 88, 255) 50%, rgb(0, 75, 236));

  background-repeat: no-repeat;
  background-size: 300% 300%;
  background-position: 10% 100%;
  background-origin: padding-box, border-box;
  animation: highlight 2s infinite alternate;
}

@keyframes highlight {
  50% {
    background-position: 30% 20%;
  }
  100% {
    background-position: 0 0;
  }
}
</style>