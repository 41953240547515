<template>
    <v-container fluid class="d-flex">
        <v-row style="width:100%">
            <v-col cols="12" sm="4" md="4">
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Préférences</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list flat class="pa-0 ma-0 rounded">
                                <v-list-item-group>
                                    <v-list-item @click="chooseComponent('Theme', 'preferencies')" :class="menuColor('Theme')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-theme-light-dark</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Thème</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="chooseComponent('Widgets', 'preferencies')" :class="menuColor('Widgets')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-widgets</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Widgets</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="chooseComponent('Mails_sync', 'preferencies')" :class="menuColor('Mails_sync')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-email-sync</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Synchronisation des mails</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="chooseComponent('Agenda_sync', 'preferencies')" :class="menuColor('Agenda_sync')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-calendar-sync</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Synchronisation de l'agenda</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="chooseComponent('Notifications', 'preferencies')" :class="menuColor('Notifications')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-bell</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Notifications</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="chooseComponent('StartPage', 'preferencies')" :class="menuColor('StartPage')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-book-open-page-variant</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Page de d'arrivée</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Général</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list flat class="pa-0 mt-3 rounded">
                                <v-list-item-group>
                                    <v-list-item @click="chooseComponent('Account', 'main')" :class="menuColor('Account')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-account</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Mon compte</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="chooseComponent('Subscriptions', 'main')" :class="menuColor('Subscriptions')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-view-list</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Abonnements</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="chooseComponent('Bills', 'main')" :class="menuColor('Bills')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-receipt</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Factures</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="chooseComponent('Payments', 'main')" :class="menuColor('Payments')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-credit-card-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Modes de paiement</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="chooseComponent('PersonalHistory', 'main')" :class="menuColor('PersonalHistory')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-history</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Historique personnel</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="chooseComponent('Help', 'main')" :class="menuColor('Help')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-account-question</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Aide / Support</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="chooseComponent('Mentions', 'main')" :class="menuColor('Mentions')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-note-search</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Mentions légales</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Sécurité</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list flat class="pa-0 mt-3 rounded">
                                <v-list-item-group>
                                    <v-list-item @click="chooseComponent('AuthorizedIP', 'security')" :class="menuColor('AuthorizedIP')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-ip-network</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Adresses IP autorisées</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="chooseComponent('Password', 'security')" :class="menuColor('Password')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-lock</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Mot de passe</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="chooseComponent('A2F', 'security')" :class="menuColor('A2F')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-two-factor-authentication</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Authentification à double facteurs</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="chooseComponent('Confidentiality', 'security')" :class="menuColor('Confidentiality')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-lock-clock</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Confidentialité</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            <v-col cols="12" sm="8" md="8">
                <component :is="selectedComponent" v-if="!loader"></component>
                <v-sheet class="pa-3" v-if="loader">
                    <v-skeleton-loader class="mx-auto" max-width="300" type="card"></v-skeleton-loader>
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Account from '../components/settings/main/Account.vue'
import { mapMutations } from 'vuex'
export default {
    name : 'Settings',
    title: 'APP-e | Paramètres',
    components : {
        Account
    },
    data () {
        return {
            loader : false,
            selectedComponent : 'Account',
            settings_changes : null,
            paramMenu : false
        }
    },
    methods : {
        ...mapMutations(),
        SaveChanges() {
            /**
             * Call axios to save change 
             */
            if(this.Ischanges) {
                if (this.settings.preferencies.theme) {
                     this.$vuetify.theme.dark = true;
                } else {
                     this.$vuetify.theme.dark = false;
                }
                this.settings_changes = JSON.stringify(this.$store.state.settings);
                this.setSettings()
                // localStorage.setItem('widget_settings', JSON.stringify(this.$store.state.settings.preferencies.widgets));
                this.Ischanges = false;
            }
        },
        chooseComponent(component, folder) {
            if(component !== this.selectedComponent) {
                if(!this.$options.components[component]) {
                    this.$options.components[component] = async() => {
                        this.loader = true;
                        return await import(`../components/settings/${folder}/${component}.vue`)
                            .then((c) => {
                                return c
                            })
                            .finally(() => {
                                this.loader = false
                            });
                    }
                } 
                this.selectedComponent = component
            }
        },
        menuColor(component) {
            if (this.selectedComponent === component) return 'nav_main--text'
        },
        menuShowHide() {
            document.getElementsByClassName('nav-params-panels').className.add('show');
        }
    },
    created () {
        this.settings_changes = this.$store.state.settings;
        console.log(this.settings_changes);
    },
    updated () {
        if (this.settings_changes !== JSON.stringify(this.$store.state.settings)) this.Ischanges = true;
        else this.Ischanges = false;
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep .v-expansion-panel-content__wrap {
        padding: 0 !important;
    }
    .title-panel {
        display: flex;
        align-items: center;
    }
    .a-custom {
        color: var(--v-text-base);
        text-decoration: none !important;
        &:hover {
            color: var(--v-nav_main-base) !important;
        }
        &:target {
            color: var(--v-nav_main-base) !important;
        }
    
        &.title {
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;
            text-decoration: none !important;
        }
    }
    .ghost {
        opacity: 0.8;
        border: 2px solid var(--v-nav_main-base); 
    }
    .chosenWidget {
        border: 2px solid yellow;
        opacity: 0;
        background: yellowgreen;
    }
</style>