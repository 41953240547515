<template>
    <div class="mb-5">
        <v-img src="https://picsum.photos/1024/400/?image=41" class="bg-image mb-5"></v-img>
        <v-row class="justify-center">
            <v-col cols="12" sm="9">
                <v-card class="app">
                    <v-toolbar class="mb-4" height="auto">
                        <v-row class="mt-1">
                            <v-col cols="6" sm="auto">
                                <v-sheet color="blue" class="my-3" width="150" height="150" rounded>
                                    <v-row class="fill-height" align="center" justify="center">
                                        <div class="display-3">
                                            Logo
                                        </div>
                                    </v-row>
                                </v-sheet>
                            </v-col>
                            <v-col cols="12" sm="9" class="mt-2">
                                <div class="text-h5 text-sm-h4 text-md-h3">APPLICATION NAME</div>
                                <span class="text-overline font-weight-light">éditeur</span>
                                <p class="text--secondary text-body-2 text-sm-body-1">
                                    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin.
                                    Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc
                                    ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
                                </p>
                                <p class="text-body-2 text-sm-body-1">
                                    Donec sed odio dui. Nullam quis risus eget urna mollis ornare vel eu leo. Cum sociis natoque
                                    penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                                </p>
                            </v-col>
                            <v-col cols="auto" class="d-flex" style="align-items:start">
                                <v-icon class="mx-3" color="success">mdi-checkbox-marked-circle-outline</v-icon>
                                <p class="text-body-2 text-sm-body-1">Vous possédez déjà cette application</p>
                            </v-col>
                        </v-row>
                    </v-toolbar>

                    <WrapperHorizontal>
                        <v-btn class="mr-4" color="primary">Accéder à l'application</v-btn>
                        <v-chip class="mx-2" color="purple" small>Catégorie 1</v-chip>
                        <v-chip class="mx-2" color="green" small>Catégorie 2</v-chip>
                    </WrapperHorizontal>

                    <v-tabs class="ml-0">
                        <v-tab>Fonctionnalités</v-tab>
                        <v-tab>Tarification</v-tab>
                        <v-tab>Infos supplémentaires</v-tab>
                        <v-tab>Avis</v-tab>

                        <v-tab-item class="pa-4">
                            <div>
                                <div class="text-h5 text-sm-h4 text-md-h3 mb-4 font-weight-light">Fonctionnalités</div>
                                <div>
                                    <div class="text-h6 font-weight-light mb-5">Les fonctionnalités de l'application</div>
                                    <p class="text-body-2 text-sm-body-1" v-for="i in 10" :key="i">• Fonctionnalités n°{{ i }}</p>
                                </div>
                                <div class="text-h5 text-sm-h4 text-md-h3 mb-5 font-weight-light">Aperçus de l'application</div>
                                <v-carousel height="auto">
                                    <v-carousel-item v-for="(color, i) in 3" :key="color" :src="`https://picsum.photos/id/13${i + 3}/1920/1080`"></v-carousel-item>
                                </v-carousel>
                            </div>
                        </v-tab-item>
                        <v-tab-item class="pa-4">
                            <div>
                                <div class="text-h5 text-sm-h4 text-md-h3 mb-4 font-weight-light">Tarification</div>
                                <div class="text-h6 font-weight-light mb-5">Offres et abonnements liés à cette application</div>

                                <WrapperHorizontal>
                                    <v-card v-for="i in 2" :key="i + 'card'" dark class="pa-3 d-inline-block mr-2" color="blue" width="500px" rounded>
                                        <div>
                                            <div class="text-h5 text-sm-h4 text-md-h3 my-2 font-weight-light">PLAN &Eacute;TUDIANTS</div>
                                            <div class="text-subtitle-2 font-weight-light mb-5">Description de l'offre</div>
                                            <div class="my-2 rounded py-2 text-center" style="background-color:#15120b57">
                                                <span class="text-h5 text-sm-h4 text-md-h3 font-weight-light">25€</span>
                                                <span class="text-h5 text-sm-h4 text-md-h3 font-weight-light">/mois</span>
                                            </div>
                                            <v-btn class="my-3" color="success" block>Accéder à l'offre</v-btn>
                                            <v-list class="rounded py-0" style="background-color:#15120b57;width:100%">
                                                <template v-for="i in 10">
                                                    <v-list-item dense class="text-body-2 text-sm-body-1" :key="i+'item'">
                                                        Fonctionnalités n°{{ i }}
                                                    </v-list-item>
                                                    <v-divider :key="i +'div'"></v-divider>
                                                </template>
                                            </v-list>
                                        </div>
                                    </v-card>
                                </WrapperHorizontal>
                            </div>
                        </v-tab-item>
                        <v-tab-item class="pa-4">
                            <div id="info-supp">
                                <div class="text-h5 text-sm-h4 text-md-h3 mb-4 font-weight-light">Informations supplémentaires</div>
                                <div class="text-h6 font-weight-light mb-5">
                                    Les informations complémentaires
                                </div>
                            </div>
                        </v-tab-item>
                        <v-tab-item class="pa-4">
                            <div id="avis">
                                <div class="text-h5 text-sm-h4 text-md-h3 mb-4 font-weight-light">Avis</div>
                                <div class="text-h6 text-sm-h5 text-md-h4 font-weight-light mb-5">Commentaires sur cette application</div>
                                <div>
                                    <div class="text-h6 text-sm-h5 text-md-h4">Note sur 5</div>
                                    <v-rating v-model="rating" class="my-2" background-color="secondary" color="secondary" size="30" dense></v-rating>
                                    <v-textarea solo outlined name="input-7-4" label="Ecrivez un commentaire"></v-textarea>
                                    <v-btn color="primary" block>Soummettre</v-btn>
                                    <v-divider class="my-4"></v-divider>

                                    <v-row class="my-3">
                                        <v-col cols="12" sm="6">
                                            <div class="d-block" v-if="global_rating">
                                                <div class="text-h5 text-sm-h4 text-md-h3 d-inline-block font-weight-light">{{ 'Note générale ' + global_rating }}</div>
                                                <v-rating v-model="global_rating" readonly background-color="secondary" color="secondary" size="30" dense></v-rating>
                                            </div>
                                            <div class="mt-2 text-h5 text-sm-h4 font-weight-light">{{ commentaries.length + ' avis' }}</div>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <div class="d-flex" style="align-items:center">
                                                <span>5</span>
                                                <v-icon color="secondary" class="mx-1">mdi-star</v-icon>
                                                <v-progress-linear class="mx-1" height="5px" color="success" :value="values.five.count / commentaries.length * 100"></v-progress-linear>
                                                <span>{{ values.five.count || 0 }}</span>
                                            </div>
                                            <div class="d-flex" style="align-items:center">
                                                <span>4</span>
                                                <v-icon color="secondary" class="mx-1">mdi-star</v-icon>
                                                <v-progress-linear class="mx-1" height="5px" color="success" :value="values.four.count / commentaries.length * 100"></v-progress-linear>
                                                <span>{{ values.four.count || 0 }}</span>
                                            </div>
                                            <div class="d-flex" style="align-items:center">
                                                <span>3</span>
                                                <v-icon color="secondary" class="mx-1">mdi-star</v-icon>
                                                <v-progress-linear class="mx-1" height="5px" color="warning" :value="values.three.count / commentaries.length * 100"></v-progress-linear>
                                                <span>{{ values.three.count || 0 }}</span>
                                            </div>
                                            <div class="d-flex" style="align-items:center">
                                                <span>2</span>
                                                <v-icon color="secondary" class="mx-1">mdi-star</v-icon>
                                                <v-progress-linear class="mx-1" height="5px" color="red" :value="values.two.count / commentaries.length * 100"></v-progress-linear>
                                                <span>{{ values.two.count || 0 }}</span>
                                            </div>
                                            <div class="d-flex" style="align-items:center">
                                                <span>1</span>
                                                <v-icon color="secondary" class="mx-1">mdi-star</v-icon>
                                                <v-progress-linear class="mx-1" height="5px" color="red" :value="values.one.count / commentaries.length * 100"></v-progress-linear>
                                                <span>{{ values.one.count || 0 }}</span>
                                            </div>
                                        </v-col>
                                    </v-row>    
                                    
                                    <v-list>
                                        <v-list-item-content class="py-1">
                                            <v-list-item-title class="text-h5 text-sm-h4 text-md-h3 ml-3">
                                                Commentaires
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-divider></v-divider>
                                        <template v-for="(item, key) in commentaries">
                                            <v-list-item :key="'A' + key">
                                                <v-list-item-icon>
                                                    <v-avatar color="primary">JD</v-avatar>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <span class="d-block d-sm-inline-block mr-sm-4">{{ item.username }}</span>
                                                        <small class="d-block d-sm-inline-block text--secondary">25 juin 2020</small>
                                                    </v-list-item-title>
                                                    <span class="d-inline-block" style="align-self:center">
                                                        <small>{{ item.user_rating }}</small>
                                                        <v-icon color="secondary" small class="ml-1" v-for="star in item.user_rating" :key="star">mdi-star</v-icon>
                                                    </span>    
                                                    <div class="text-body-2 text-sm-body-1 my-3">{{ item.message }}</div>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider :key="'B' + key"></v-divider>
                                        </template>
                                    </v-list>
                                </div>
                            </div>
                        </v-tab-item>
                    </v-tabs>

                    <div id="similar-app" class="ma-4">
                        <div class="text-h5 text-sm-h4 text-md-h3 font-weight-light">Applications similaires</div>
                        <WrapperHorizontal>
                            <AppCard
                                v-for="index in 4" :key="index + 'app'"
                                title="NOM APPLICATION"
                                category="Categorie"
                                description="Description de l'application, passez la souris sur la carte pour en découvrir un peu plus" 
                                :img_top="'https://picsum.photos/id/' + index * 8 +'/250/200'"
                                :rating_global="4.5"
                                :rating_count="214"
                                >
                            </AppCard>
                        </WrapperHorizontal>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import AppCard from '../../../components/AppCardMax';
import WrapperHorizontal from '../../../components/WrapperHorizontal'

export default {
    name : 'App-page',
    components : {
        AppCard,
        WrapperHorizontal
    },
    data() {
        return {
           prevRoute: null,
           rating : null,
           global_rating : null,
           values : {
                one : {value : 1, count : null},
                two : {value : 2, count : null},
                three : {value : 3, count : null},
                four : {value : 4, count : null},
                five : {value : 5, count : null},
           }, 
           commentaries : [
               { id : 1, username : 'Will SMIC', user_rating : 5, message : 'I\'m very happy about this app.', date : "25/01/2020"},
               { id : 1, username : 'Connor MCGREGOUSE', user_rating : 4, message : 'I\'m very happy about this app.', date : "25/01/2020"},
               { id : 1, username : 'Sylvester STALOOSE', user_rating : 5, message : 'I\'m very happy about this app.', date : "25/01/2020"},
               { id : 2, username : 'Vin GAZOIL', user_rating : 5, message : 'I\'m very happy about this app.', date : "25/01/2020"},
               { id : 3, username : 'Gérard QUATREPARTROIS', user_rating : 5, message : 'I\'m very happy about this app.', date : "25/01/2020"},
               { id : 4, username : 'Céline BIDON', user_rating : 3, message : 'I\'m very happy about this app.', date : "25/01/2020"},
               { id : 5, username : 'David GETTEUR', user_rating : 5, message : 'I\'m very happy about this app.', date : "25/01/2020"},
               { id : 6, username : 'Robert DEMIGROS', user_rating : 3, message : 'I\'m very happy about this app.', date : "25/01/2020"},
               { id : 6, username : 'Killyan MBACLER', user_rating : 4, message : 'I\'m very happy about this app.', date : "25/01/2020"},
               { id : 6, username : 'George GROSNEZ', user_rating : 5, message : 'I\'m very happy about this app.', date : "25/01/2020"},
           ]
        }
    },
    created () {
        this.commentaries.forEach(el => {
            if (el.user_rating === 1) { this.values.one.count ++}
            else if (el.user_rating === 2) { this.values.two.count ++}
            else if (el.user_rating === 3) { this.values.three.count ++}
            else if (el.user_rating === 4) { this.values.four.count ++}
            else if (el.user_rating === 5) { this.values.five.count ++}
        });

        Object.values(this.values).forEach(el => {
            this.global_rating += el.value * el.count;
        });

        this.global_rating = (this.global_rating / this.commentaries.length).toFixed(1);
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
}
</script>

<style lang="scss" scoped>
    .app {
        @media screen and (min-width:600px) {
            margin-top: -15rem;
        }
    }
    .bg-image {
        border-radius: $border-radius-root;
        position: sticky;
        top:1rem;
        max-width: 100%;

        @media screen and (max-width: 600px) {
            display: none;
        }
    }
    ::v-deep .v-slide-group__prev.v-slide-group__prev--disabled {
        position: absolute;
    }
</style>