<template>
    <v-navigation-drawer v-model="show" absolute temporary dark>
        <v-app-bar dense dark>
            <v-app-bar-nav-icon @click="closeSidebar"></v-app-bar-nav-icon>    
            <img to="/" class="py-2" height="100%" src="logo-app-e.svg" />
        </v-app-bar>
        <v-list class="wrapper pt-0">
            <v-list-item-group v-if="this.$store.state.activeUser" color="white">
                <v-list-item to="/dashboard">
                    <v-list-item-icon class="mr-2">
                        <v-icon>mdi-monitor-dashboard</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>TABLEAU DE BORD</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
            <v-list-group v-if="this.$store.state.activeUser" color="white" prepend-icon="mdi-account-tie">
                <template v-slot:activator>
                    <v-list-item-title >ADMINISTRATION</v-list-item-title>
                </template>
                <v-list-item link :to="{ name : 'administration-tabs', params : { tabname : 'subscriptions'}}">
                    <v-list-item-title class="subtitle-1 font-weight-light">Abonnements</v-list-item-title>
                    <v-list-item-icon>
                        <v-icon>mdi-playlist-check</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item link :to="{ name : 'administration-tabs', params : { tabname : 'users'}}">
                    <v-list-item-title class="subtitle-1 font-weight-light">Utilisateurs</v-list-item-title>
                    <v-list-item-icon>
                        <v-icon>mdi-account-group</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item link :to="{ name : 'administration-tabs', params : { tabname : 'tags'}}">
                    <v-list-item-title class="subtitle-1 font-weight-light">Groupes</v-list-item-title>
                    <v-list-item-icon>
                        <v-icon>mdi-folder</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item link :to="{ name : 'administration-tabs', params : { tabname : 'roles'}}">
                    <v-list-item-title class="subtitle-1 font-weight-light">Rôles</v-list-item-title>
                    <v-list-item-icon>
                        <v-icon>mdi-shield-account</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item link :to="{ name : 'administration-tabs', params : { tabname : 'companies'}}">
                    <v-list-item-title class="subtitle-1 font-weight-light">Sociétés</v-list-item-title>
                    <v-list-item-icon>
                        <v-icon>mdi-folder-text</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list-group>

            <v-list-group color="white" prepend-icon="mdi-store">
                <template v-slot:activator>
                    <v-list-item-title>STORE</v-list-item-title>
                </template>
                <v-list-item link to="/store" exact>
                    <v-list-item-title class="subtitle-1 font-weight-light">Accueil</v-list-item-title>
                    <v-list-item-icon>
                        <v-icon>mdi-storefront</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item link to="/store/recommanded">
                    <v-list-item-title class="subtitle-1 font-weight-light">Recommandées</v-list-item-title>
                    <v-list-item-icon>
                        <v-icon>mdi-comment-search</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                    <v-list-item link to="/store/offers">
                    <v-list-item-title class="subtitle-1 font-weight-light">Offres</v-list-item-title>
                    <v-list-item-icon>
                        <v-icon>mdi-tag</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list-group>

            <v-list-group v-if="this.$store.state.activeUser" color="white" prepend-icon="mdi-library">
                <template v-slot:activator>
                    <v-list-item-title>BIBLIOTH&Egrave;QUE</v-list-item-title>
                </template>
                <v-list-item link>
                    <v-list-item-title class="subtitle-1 font-weight-light">Google Docs</v-list-item-title>
                </v-list-item>
                <v-list-item link>
                    <v-list-item-title class="subtitle-1 font-weight-light">G-mail</v-list-item-title>
                </v-list-item>
                <v-list-item link>
                    <v-list-item-title class="subtitle-1 font-weight-light">Trello</v-list-item-title>
                </v-list-item>
                <v-list-item link>
                    <v-list-item-title class="subtitle-1 font-weight-light">Prestashop</v-list-item-title>
                </v-list-item>
                <v-list-item link>
                    <v-list-item-title class="subtitle-1 font-weight-light">Zendesk</v-list-item-title>
                </v-list-item>
            </v-list-group>

            <v-list-item-group color="white">
                <v-list-item to="/forum">
                    <v-list-item-icon class="mr-2">
                        <v-icon>mdi-account-group-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>COMMUNAUT&Eacute;</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
  
            <v-list-group v-if="this.$store.state.activeUser" color="white" prepend-icon="mdi-cog">
                <template v-slot:activator>
                <v-list-item-title >PARAM&Egrave;TRES</v-list-item-title>
                </template>
                <v-list-item link @click="goToHash('/settings', 'preferencies')">
                <v-list-item-title class="subtitle-1 font-weight-light">Préférences</v-list-item-title>
                <v-list-item-icon>
                    <v-icon>mdi-dots-grid</v-icon>
                </v-list-item-icon>
                </v-list-item>
                <v-list-item link @click="goToHash('/settings', 'main')">
                <v-list-item-title class="subtitle-1 font-weight-light">Général</v-list-item-title>
                <v-list-item-icon>
                    <v-icon>mdi-cogs</v-icon>
                </v-list-item-icon>
                </v-list-item>
                <v-list-item link @click="goToHash('/settings', 'security')">
                <v-list-item-title class="subtitle-1 font-weight-light">Sécurité</v-list-item-title>
                <v-list-item-icon>
                    <v-icon>mdi-shield-key</v-icon>
                </v-list-item-icon>
                </v-list-item>
            </v-list-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name : 'SideBar',
    props : {
        show : {
            type : Boolean,
            default : true
        }
    },
    methods: {
        closeSidebar() {
            this.$emit('update' , false);
        }
    },
    created() {
        console.log("sidebarrr");
    }
}
</script>

<style lang="scss" scoped>
    .v-navigation-drawer {
        color:white; 
        background: linear-gradient(70deg, rgb(0 110 255) 0%, rgb(155 0 194) 100%) !important;
    }
    .v-toolbar.v-sheet {
        background: none !important;
    }
</style>