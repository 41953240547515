<template>
    <div class="px-2 pb-2" style="position:relative;">
        <div id="wrapperHz" class="wrapper-cards pb-6 px-4 w-100" @scroll="btnShowing">
            <v-btn v-if="isBtn" class="btn-scroller left" @click="scrollerX('left')"><v-icon large>mdi-chevron-left</v-icon></v-btn>
            <slot/>
            <v-btn v-if="isBtn" class="btn-scroller right" @click="scrollerX('right')"><v-icon large>mdi-chevron-right</v-icon></v-btn>
        </div>
    </div>
</template>
<script>
export default {
    name : 'WrapperHorizontal',
    props: {
        showBtn : {
            type : Boolean,
            default : true
        }
    },
    data() {
        return {
            isBtn: this.showBtn
        }
    },
    methods: {
        btnShowing() {
            const wrapper = this.$el.querySelector('#wrapperHz');
            const btnLeft = wrapper.querySelector('.btn-scroller.left');
            const btnRight = wrapper.querySelector('.btn-scroller.right');

            if (window.innerWidth <= 600) {
                return this.isBtn = false;
            } else {
                if (this.showBtn) this.isBtn = true;
                else return this.isBtn = false;
            }

            if (wrapper.clientWidth == wrapper.scrollWidth) {
                btnRight.style.visibility = "hidden";
                btnLeft.style.visibility = "hidden";
            } else {
                btnRight.style.visibility = "visible";
                btnLeft.style.visibility = "visible";

                if (wrapper.scrollLeft <= 0) btnLeft.style.opacity = 0;
                else btnLeft.style.opacity = 0.7;
                
                if (wrapper.scrollLeft + wrapper.clientWidth >= wrapper.scrollWidth) btnRight.style.opacity = 0;
                else btnRight.style.opacity = 0.7;
            }
        },
        scrollerX(side) {
            const wrapper = this.$el.querySelector('#wrapperHz');
            let scrollValue = 0;
            if (side === 'left') scrollValue = -(wrapper.clientWidth/2);
            else if (side === 'right') scrollValue = wrapper.clientWidth/2;

            wrapper.scrollBy({
                top: 0,
                left: scrollValue, 
                behavior: 'smooth' 
            });
        }
    },
    created() {
        window.addEventListener("resize", this.btnShowing);
    },
    mounted() {
        this.btnShowing();
    },
}
</script>

<style lang="scss" scoped>
    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    .wrapper-cards {
        max-height: inherit !important;
        overflow-x:auto;
        overflow-y:hidden;
        white-space:nowrap;
    }

    .btn-scroller {
        z-index: 2;
        opacity: 0;
        position: absolute;
        min-width:20px !important;
        width: 50px;
        top : 15%;
        height: 70% !important;
        background-color: rgb(45, 49, 70);
        transition: opacity 0.5s ease-in-out;

        &:hover {
            opacity: 0.95 !important;
        }
    
        &.left {
            left: 0.75rem;
            float: left;
        }
       
        &.right {
            right: 0.75rem;
            float: right;
        }
    }
</style>