import store from '../store/index';

/**
 * Check authentification
 * 
 * @param {*} to 
 * @param {*} from 
 * @param {*} next 
 * @returns next page
 */
function isAuthenticate(to, from, next) {
    const TOKEN = store.getters.activeUser;
    if (!TOKEN) next({ name : 'home' });
    next();
}

export default isAuthenticate;