<template>
    <div style="text-align: center; height:90%;display: flex;align-items: center;justify-content: center;">
        <img to="/" max-width="100%" src="logo-app-e_full.svg" />
    </div>
</template>

<script>
export default {
    name : 'Home',
    title: 'APP-e',
}
</script>
