import Vue from 'vue'

// Views
import Home from './views/Home.vue'
import Dashboard from './views/dashboard/Dashboard.vue'
import Store from './views/store/Store.vue'
import App_page from './views/store/app-page/App-page.vue'
import Login from './views/Login.vue'
import Register from './views/Register.vue'
import Administration from './views/administration/Administration.vue'
import Library from './views/library/Library.vue'
import Settings from './views/Settings.vue'
import NotFound from './views/404.vue'
import Community from './views/Community.vue'
import Post from './views/Posts.vue'

//Components
import StoreHome from './components/store/default/StoreHome.vue'
import Offers from './components/store/offers/Offers.vue'
import Category from './components/store/categories/Category.vue'
import Search from './components/store/search/Search.vue'

// Router
import VueRouter from 'vue-router';
Vue.use(VueRouter);

// Check authentification
import checkAuth from './mixins/IsAuthenticate'

// Routes
const routes = [
  {
    path : '/',
    name : 'home',
    component : Home
  },
  {
    path : '/forum',
    name : 'Community',
    component : Community
  },
  {
    path : '/forum/post',
    name : 'Post',
    component : Post
  },
  {
    path : '/dashboard',
    name : 'dashboard',
    component : Dashboard,
    beforeEnter: checkAuth
  },
  {
    path : '/store',
    name : 'store',
    redirect : StoreHome,
    component : Store,
    children: [{
      path: '',
      name : 'StoreHome',
      component: StoreHome
    }, {
      path: 'search',
      name : 'search',
      component: Search
    }, {
      path: 'category/:name',
      name : 'category',
      component: Category
    }, {
      path: 'offers',
      component: Offers
    }]
  },
  {  
    path: '/application/:name',
    name : 'app-page',
    component: App_page
  },
  {
    path : '/login',
    name : 'login',
    component : Login
  },
  {
    path : '/register',
    name : 'register',
    component : Register
  },
  {
    path : '/administration',
    name : 'administration',
    component : Administration,
    beforeEnter: checkAuth,
    props: true,
  },
  {
    path : '/administration/:tabname',
    name : 'administration-tabs',
    component : Administration,
    beforeEnter: checkAuth,
    props: true,
  },
  {
    path : '/library',
    name : 'library',
    component : Library,
    beforeEnter: checkAuth,
  },
  {
    path : '/settings',
    name : 'settings',
    component : Settings,
    beforeRouteEnter: checkAuth,
  },
  {
    path : '*',
    name : 'notfound',
    component : NotFound
  }
]

export default new VueRouter({
  routes,
  mode : 'history'
});
