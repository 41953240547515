<template>
    <v-container fluid>
        <v-card class="rounded pa-2">
            <v-row>
                <v-col cols="12" sm="6">
                     <v-btn color="primary" @click="$router.go('-1')">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="6" align="center">
                    <v-btn color="primary">
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        Créer un sujet
                    </v-btn>
                </v-col>
            </v-row>
            <div class="pa-3">
                <v-chip dark color="success" class="mb-3" small>Résolu</v-chip>
                <div class="text-h3 font-weight-light">Titre du sujet</div>
                <div class="overline">Nom de l'application</div>
                <div class="d-flex align-center mb-5">
                    <v-avatar dark color="primary" class="mr-3">NU</v-avatar>
                    <div>
                        <div class="caption font-weight-light text--secondary">Nom utilisateur</div>
                        <div class="caption font-weight-light text--secondary">Il y a 1h</div>
                    </div>
                </div>
                <div class="body-2 font-weight-light mb-4">
                    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin.
                    Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc
                    ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
                    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin.
                    Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc
                    ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
                    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin.
                    Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc
                    ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
                    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin.
                    Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc
                    ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
                    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin.
                    Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc
                    ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
                </div>
                <v-divider></v-divider>
                <div class="my-3">
                    <v-btn-toggle dense  dark multiple>
                        <v-btn>
                            <v-icon>mdi-format-bold</v-icon>
                        </v-btn>
                        <v-btn>
                            <v-icon>mdi-format-italic</v-icon>
                        </v-btn>
                        <v-btn>
                            <v-icon>mdi-format-underline</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                    <v-textarea
                        filled
                        name="input-7-4"
                        label="Ajoutez une réponse"
                    ></v-textarea>
                    <v-btn color="primary">Envoyer la réponse</v-btn>
                </div>
                <v-divider></v-divider>
                <div class="my-4">
                    <v-row align="center">
                        <v-col cols="6" sm="3">
                            <v-chip class="subtitle-1 mb-2">32 réponses</v-chip>
                        </v-col>
                        <v-col cols="6" sm="9" align="end">
                            <v-btn-toggle borderless>
                                <v-btn value="left" color="primary">
                                    <v-icon>mdi-thumbs-up-down</v-icon>
                                </v-btn>
                                <v-btn value="right" color="primary">
                                    <v-icon>mdi-sort-clock-descending</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <v-list class="mb-4">
                        <v-divider></v-divider>
                        <v-list-item class="py-4" style="border:2px solid #00EC15FF">
                            <v-row align="center">
                                <v-col cols="3" sm="1">
                                    <v-avatar class="mb-2" style="color:white;align-self:center" color="primary">JD</v-avatar>
                                </v-col>
                                <v-col cols="4" sm="2">
                                    <div class="caption font-weight-light">Nom utilisateur</div>
                                </v-col>
                                <v-col cols="4" sm="2">
                                    <div class="d-flex align-center caption">
                                        <v-icon color="green">mdi-thumb-up-outline</v-icon>
                                        <div class="mx-1">36</div>
                                        <v-icon color="red">mdi-thumb-down-outline</v-icon>
                                        <div class="ml-1">2</div>
                                    </div>
                                </v-col>
                                <v-col cols="4" sm="4">
                                    <div class="caption text--secondary font-weight-light">Il y a 50min</div>
                                </v-col>
                                <v-col cols="12">
                                    <div class="caption font-weight-light">
                                        Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin.
                                        Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc
                                        ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
                                    </div>
                                </v-col>
                            </v-row>
                        </v-list-item>
                        <v-divider></v-divider>
                        <template v-for="i in 10">
                            <v-list-item class="py-2" :key="i+'rep'">
                                <v-row align="center">
                                    <v-col cols="3" sm="1">
                                        <v-avatar class="mb-2" style="color:white;align-self:center" color="primary">JD</v-avatar>
                                    </v-col>
                                    <v-col cols="4" sm="2">
                                        <div class="caption font-weight-light">Nom utilisateur</div>
                                    </v-col>
                                    <v-col cols="4" sm="2">
                                        <div class="d-flex align-center caption">
                                            <v-icon color="green">mdi-thumb-up-outline</v-icon>
                                            <div class="mx-1">36</div>
                                            <v-icon color="red">mdi-thumb-down-outline</v-icon>
                                            <div class="ml-1">2</div>
                                        </div>
                                    </v-col>
                                    <v-col cols="4" sm="4">
                                        <div class="caption text--secondary font-weight-light">Il y a 50min</div>
                                    </v-col>
                                    <v-col cols="12">
                                        <div class="caption font-weight-light">
                                            Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin.
                                            Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc
                                            ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-list-item>
                            <v-divider :key="i+'div'"></v-divider>
                        </template>
                    </v-list>
                    <div class="text-center mb-9">
                        <v-pagination class="mb-4" :length="6"></v-pagination>
                    </div>
                    <div class="text-h4 mb-2 font-weight-light">Autres sujets</div>
                    <WrapperHorizontal>
                        <v-card class="d-inline-block mr-4 mb-4" color="#007CBEFF" min-width="500" dark @click="$router.push({ name : 'Post'})" v-for="i in 5" :key="i + 'post'">
                            <v-list color="#007CBEFF" width="500">
                                <v-chip color="success" class="ml-5 overline" small>Résolu</v-chip>
                                <v-list-item three-line>
                                    <v-list-item-avatar tile size="90" color="grey"></v-list-item-avatar>
                                    <v-list-item-content>
                                        <div class="d-flex align-center">
                                            <div class="overline mr-3">Application name</div>
                                        </div>
                                        <div class="text-h4 font-weight-light">Titre du sujet</div>
                                        <div class="caption text--secondary">Description du sujet</div>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <div class="caption text--secondary">Il y a 1h</div>
                                        <div class="caption">32 réponses</div>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </WrapperHorizontal>
                </div>
            </div>
        </v-card>
    </v-container>
</template>
<script>
import WrapperHorizontal from '../components/WrapperHorizontal.vue'
export default {
    name : 'Posts',
    title : 'APP-e | Post',
    components : {
        WrapperHorizontal
    }
}
</script>