<template>
    <div class="admin-panel">
        <v-card class="my-6">
            <v-tabs class="overflow-hidden" v-model="tabname">
                <v-tabs-slider></v-tabs-slider>
                <v-tab href="#subscriptions">Abonnements</v-tab>
                <v-tab href="#users">Utilisateurs</v-tab>
                <v-tab href="#tags">Groupes</v-tab>
                <v-tab href="#roles">Rôles</v-tab>
                <v-tab href="#companies">Sociétés</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabname">
                <v-tab-item value="subscriptions">
                    <v-data-table :headers="fields_sub" :items="items_sub"  item-key="nom_abonnement" show-select class="mt-4" mobile-breakpoint="0">
                        <template v-slot:[`item.state`]="{ item }">
                            <template v-if="item.state === 'Actif'">
                                <v-chip color="green" label small>
                                    {{ item.state }}
                                </v-chip>
                            </template>
                            <template v-if="item.state === 'Suspendu'">
                                <v-chip color="orange" label small>
                                    {{ item.state }}
                                </v-chip>
                            </template>
                            <template v-if="item.state === 'Erreur de paiement'">
                                <v-chip color="red" label small>
                                    {{ item.state }}
                                </v-chip>
                            </template>
                        </template>

                        <template v-slot:[`item.nom_abonnement`]="{ item }">
                            <strong>{{ item.nom_abonnement }}</strong>
                        </template>
                        
                        <template v-slot:[`item.action`]>
                            <v-btn color="primary" small>Modifier</v-btn>
                        </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item value="users">

                    <v-data-table v-model="users" :headers="fields_users" :items="items_users"  item-key="id" show-select class="v-data-table-header" mobile-breakpoint="0">
                        <template v-slot:[`item.state`]="{ item }">
                            <template v-if="item.state === 'Actif'">
                                <v-chip color="green" label small>
                                    {{ item.state }}
                                </v-chip>
                            </template>
                            <template v-else>
                                <v-chip color="red" label small>
                                    {{ item.state }}
                                </v-chip>
                            </template>
                        </template>
                        <template v-slot:[`item.action`]>
                            <v-btn color="primary" small>Modifier</v-btn>
                        </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item value="tags">
                    <v-data-table v-model="users" :headers="fields_tag" :items="items_tag"  item-key="id" show-select class="v-data-table-header" mobile-breakpoint="0">
                        <template v-slot:[`item.state`]="{ item }">
                            <template v-if="item.state === 'Actif'">
                                <v-chip color="green" label small>
                                    {{ item.state }}
                                </v-chip>
                            </template>
                            <template v-else>
                                <v-chip color="red" label small>
                                    {{ item.state }}
                                </v-chip>
                            </template>
                        </template>
                        <template v-slot:[`item.action`]>
                            <v-btn color="primary" small>Modifier</v-btn>
                        </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item value="roles">
                    <v-form class="ma-5" style="width:50%" ref="form" lazy-validation>
                        <v-btn color="success">
                            Attribuer des rôles
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-form>
                    <v-data-table mobile-breakpoint="0">

                    </v-data-table>
                </v-tab-item>
                <v-tab-item value="companies">
                    <v-data-table mobile-breakpoint="0">

                    </v-data-table>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
export default {
    name : 'Administration',
    title: 'APP-e | Administration',
    data() {
      return {
        tabname : this.$route.params.tabname,
        users : [],
        tags : [],
        // Subscription table
        fields_sub: [
            { value : 'logo', text : 'Logo', sortable: false, }, 
            { value : 'nom_abonnement', text : 'Abonnement', sortable : true }, 
            { value : 'nombre_users', text : 'Nombre d\'utilisateurs', sortable : true }, 
            { value : 'montant_mensuel', text : 'Montant Mensuel', sortable : true }, 
            { value : 'montant_annuel', text : 'Montant Annuel', sortable : true },
            { value : 'state', text : 'État', sortable : true },
            { value : 'action', text : 'Action', sortable: false, },
        ],
        items_sub: [
            { logo : 'img', nom_abonnement: 'G-Suite', nombre_users : '5', montant_mensuel : '100€', montant_annuel : '1200€', state : 'Actif' },
            { logo : 'img', nom_abonnement: 'Microsoft Office 2020', nombre_users : '5', montant_mensuel : '100€', montant_annuel : '1200€', state : 'Suspendu' },
            { logo : 'img', nom_abonnement: 'Adobe Créative Cloud', nombre_users : '5', montant_mensuel : '100€', montant_annuel : '1200€', state : 'Actif' },
            { logo : 'img', nom_abonnement: 'Amazon Prime', nombre_users : '5', montant_mensuel : '100€', montant_annuel : '1200€', state : 'Erreur de paiement' },
            { logo : 'img', nom_abonnement: 'Quickbooks Essentials', nombre_users : '5', montant_mensuel : '125€', montant_annuel : '1500€', state : 'Actif' },
            { logo : 'img', nom_abonnement: 'Salesforces', nombre_users : '5', montant_mensuel : '125€', montant_annuel : '1500€', state : 'Actif' },
            { logo : 'img', nom_abonnement: 'EasyCron premium', nombre_users : '5', montant_mensuel : '125€', montant_annuel : '1500€', state : 'Actif' },
            { logo : 'img', nom_abonnement: 'Linkedin', nombre_users : '5', montant_mensuel : '125€', montant_annuel : '1500€', state : 'Actif' },
            { logo : 'img', nom_abonnement: 'Slack Enterprise Grid', nombre_users : '5', montant_mensuel : '125€', montant_annuel : '1500€', state : 'Actif' },
        ],
        // Users table
        fields_users: [
            { value : 'lastname', text : 'Nom' },
            { value : 'firstname', text : 'Prénom' },
            { value : 'email', text : 'E-mail' },
            { value : 'type', text : 'Type' },
            { value : 'tags', text : 'Groupes' },
            { value : 'state', text : 'état' },
            { value : 'action', text : 'Action' },
        ],
        items_users: [
            { id : '1', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '2', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '3', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '4', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '5', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '6', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '7', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '8', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '9', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '10', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '11', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '12', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '13', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '14', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '15', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '16', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '17', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '18', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '19', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '20', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '21', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
            { id : '22', lastname : 'DOE', firstname : 'John', email : 'johndoe@mail.com', type : 'Employé', tags : 'Administration et 1 autres...', state :'Actif' },
        ],
        // Tags table
        fields_tag: [
            { value : 'tag_name', text : 'Nom du groupe' },
            { value : 'society_name', text : 'Société' },
            { value : 'description', text : 'Description' },
            { value : 'users_count', text : 'Nombre d\'utlisateurs' },
            { value : 'state', text : 'état' },
            { value : 'action', text : 'Action' },
        ],
        items_tag: [
            { id : "1", tag_name : 'Administration', society_name : 'Mon entreprise A + 3 autres...', description : 'Description du groupe', users_count : '15', state : 'Actif' },
            { id : "2", tag_name : 'Digital', society_name : 'Mon entreprise B + 1 autre...', description : '', users_count : '7', state : 'Actif' },
        ],
      }
    },
    watch: { 
        $route() {
            this.tabname = this.$route.params.tabname;
        } 
    }
}
</script>

<style lang="scss" scoped>
    .v-tabs, .v-tab, .v-tabs-item, .v-data-table {
        background: none;
    }

    .v-tab--active {
        color : var(--v-primary-lighten2) !important;
    }

    .admin-panel {
        position: relative;
        z-index: 3;
        border-radius: $border-radius-root;

        > .v-card {
            position: relative;
            overflow: hidden;
            transition: 0.3s ease-in-out !important;
            
            &.theme--dark {
                box-shadow: inset 0px 0px rgba(0, 98, 226, 0.6) !important;
                &:hover {
                    box-shadow: inset 10px 10px 20px -15px rgb(79,0,161, 1) !important;
                }
            }
        }
    }

    *.theme--dark .admin-panel {
        @include onHoverItemBefore(linear-gradient(195deg, rgb(123, 0, 255) 0%, rgba(255,0,132,1) 100%), 100%, 100%);
    }

    *.theme--light .admin-panel {
        @include onHoverItemBefore(linear-gradient(195deg, rgb(255, 0, 234) 0%, rgba(255,0,132,1) 100%), 100%, 100%);
    }
</style>