<template>
  <div class="display">
        <div :class="'icon size-'+ size">
            <img src="https://picsum.photos/id/652/200/200">
        </div>
        <div v-if="isTitle" class="overline text-center">Nom application</div>
  </div>
</template>

<script>
export default {
    name: "AppCardMin",
    props: {
        size: {
            type : String,
            default: 'm'
        },
        isTitle: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style lang="scss" scoped>

    .display {
        display: inline-block;
    }

    .icon {
        border-radius: $border-radius-root;

        &.size-s {
            height: 75px;
            margin: 10px;
        }

        &.size-m {
            height: 150px;
            margin: 20px;
        }

        &.size-xl {
            height: 200px;
            margin: 20px;
        }

        &[class*=size] {
            position: relative;
            z-index: 3;

            > img {
                border-radius: inherit;
                height: inherit;
                width: inherit;
            }
        }
    }

    *.theme--dark .icon  {
        @include onHoverItemBefore(linear-gradient(195deg, rgb(255, 196, 0) 20%, rgb(144, 94, 0) 100%), calc(100% + 5px),  calc(100% + 5px));
    }

    *.theme--light .icon  {
        @include onHoverItemBefore(linear-gradient(195deg, rgb(255, 0, 234) 0%, rgba(255,0,132,1) 100%), 100%, 100%);
    }
</style>