<template>
    <v-container fluid>
        <v-row class="mb-4 justify-center">
            <v-col cols="8">
                <v-text-field 
                    v-model="search" 
                    v-on:keyup.enter="() => search && $route.query.research !== search ? $router.push({ path: '/store/search', query: { research : search, page : 1 }}) : null" 
                    @click:append="() => search && $route.query.research !== search ? $router.push({ path: '/store/search', query: { research : search, page : 1 }}) : null" 
                    append-icon="mdi-magnify" 
                    label="Rechercher" 
                    single-line hide-details>
                </v-text-field>
            </v-col>
        </v-row>
        <v-row class="my-2 justify-center">
            <v-col cols="auto" v-for="i in 18" :key="i">
                <!-- <AppCard
                    title="NOM APPLICATION"
                    category="Categorie"
                    description="Description de l'application, passez la souris sur la carte pour en découvrir un peu plus" 
                    :img_top="'https://picsum.photos/id/' + i * 5 +'/250/200'"
                    :rating_global="4.5"
                    :rating_count="214"
                    :col_display="true"
                    url="/application/Example"
                    >
                </AppCard> -->
                <AppCard />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AppCard from '../../components/AppCardMin';

// Check authentification
// import checkAuth from '../../mixins/IsAuthenticate'

export default {
    name : 'Library',
    title: 'APP-e | Bibliothèque',
    components : {
        AppCard
    },
    created() {
        // checkAuth(this.$cookies);
    }
}
</script>