<template>
    <div>
        <draggable 
            v-model="widget_settings"
            @change="saveChanges"
            ghost-class="ghost" 
            dragClass="chosenWidget"
            animation="500" 
            easing="cubic-bezier(1, 0, 0, 1)" 
            :class="'pa-3 widgetGrid ' + (enableDrag ? ' drag ' : '')" 
            :disabled="true">
            <component 
                v-for="(component, index) in widget_settings"
                :is="component.name" 
                :key="`Component-${index}`" 
                :size="component.size"
                :class="'widget' + (enableDrag ? ' pointer-effect ' : '')"
            
            ></component>
        </draggable>
    </div>
</template>

<script>
import WrapperHorizontal from '../../components/WrapperHorizontal.vue';
import AppCardMin from '../../components/AppCardMin.vue';
import draggable from 'vuedraggable'

export default {
    name : 'Dashboard',
    components : {
        draggable,
        WrapperHorizontal,
        AppCardMin
    }, 
    data () {
        return {
            enableDrag: false,
            widget_settings : this.$store.state.settings.preferencies.widgets,
            users : [
                { firstname : 'John', lastname : 'DOE', isOnline : true },
                { firstname : 'Vin', lastname : 'Gazoil', isOnline : true },
                { firstname : 'Will', lastname : 'SMITH', isOnline : true },
                { firstname : 'Harry', lastname : 'Potter', isOnline : false },
                { firstname : 'Megane', lastname : 'FOX', isOnline : true },
                { firstname : 'Barack', lastname : 'OBAMA', isOnline : false },
                { firstname : 'Johnny', lastname : 'Halliday', isOnline : false },
                { firstname : 'Rachel', lastname : 'Green', isOnline : true },
            ],
        }
    },
    methods : {
        makeDraggable() {
            this.enableDrag = !this.enableDrag;
        },
        saveChanges() {
            const settings = JSON.parse(localStorage.getItem('settings'));
            settings.preferencies.widgets = this.widget_settings;
            localStorage.setItem('settings', JSON.stringify(settings));
        }
    },
    async created() {
        await this.widget_settings.forEach(widget => { // Add components
            this.$options.components[widget.name] = () => import(`../../components/widgets/${widget.name}.vue`);
        });
    }
}
</script>

<style lang="scss" scoped>

    ::v-deep >.wrapper {
        margin: 0 0 0 0;
    }
        
    .widgetGrid {
        transition: 0.2s linear;
        display: grid;
        grid-auto-rows: 5em;
        grid-auto-flow: row;
        @media screen and (max-width: 600px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-gap: 1rem;
        }
        @media screen and (min-width: 600px) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
            grid-gap: 0.75rem;
        }
        @media screen and (min-width: 960px) {
            grid-template-columns: repeat(6, minmax(0, 1fr));
            grid-gap: 1rem;
        }
        @media screen and (min-width: 1200px) {
            grid-template-columns: repeat(8, minmax(0, 1fr));
            grid-gap: 1.5rem;
        }
    }

    .ghost {
        opacity: 0.8;
        border: 2px solid var(--v-nav_main-base); 
    }

    .chosenWidget {
        border: 2px solid yellow;
        opacity: 0;
        background: yellowgreen;
    }

    .pointer-effect {
        cursor: move;
        cursor: -webkit-grabbing;
    }

    ::v-deep .widget {

        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 3;
        border-radius: $border-radius-root;
        
        > .v-card {
            display: flex;
            flex-direction: column;
            height: 100% !important;
            position: relative;
            overflow: hidden;
            transition: 0.3s ease-in-out !important;
            padding: 10px;
            
            &.theme--dark {
                box-shadow: inset 0px 0px rgba(0, 98, 226, 0.6) !important;
                &:hover {
                    box-shadow: inset 10px 10px 20px -15px rgb(79,0,161, 1) !important;
                }
            }
        }
    }

    *.theme--dark .widget  {
        @include onHoverItemBefore(linear-gradient(195deg, rgb(123, 0, 255) 0%, rgba(255,0,132,1) 100%), 100%, 100%);
    }

    *.theme--light .widget  {
        @include onHoverItemBefore(linear-gradient(195deg, rgb(255, 0, 234) 0%, rgba(255,0,132,1) 100%), 100%, 100%);
    }
</style>

