<template>
    <div>
        <v-row class="mb-4">
            <template v-if="Object.keys($route.params).length > 0">
                <v-col class="d-flex">
                    <v-btn color="red" size="sm" @click="removeAllFilters">Reinitialiser les filtres</v-btn>
                    <div class="ml-2 align-self-center">
                        <v-chip color="secondary" text-color="black" close @click:close="remove_param(index)" class="pl-2" v-for="(param, index) in $route.params" :key="index">
                            <strong>{{ param }}</strong>
                        </v-chip>
                    </div>
                </v-col>
            </template>
        </v-row>
        <v-row class="mb-5 justify-center">
            <v-col cols="auto" v-for="i in 18" :key="i">
                <AppCard
                    title="NOM APPLICATION"
                    category="Categorie"
                    description="Description de l'application, passez la souris sur la carte pour en découvrir un peu plus" 
                    :img_top="'https://picsum.photos/id/' + i * 5 +'/250/200'"
                    :rating_global="4.5"
                    :rating_count="214"
                    :col_display="true"
                    url="/application/Example"
                    >
                </AppCard>
            </v-col>
        </v-row>
        <div class="text-center mb-9">
            <v-pagination class="mb-4" v-model="page" :length="6" @input="link_page"></v-pagination>
        </div>
    </div>
</template>

<script>
import AppCard from '../../AppCardMax';

export default {
    name : 'Category',
    components : {
        AppCard
    },
    data() {
        return {
            tags : [],
            page : this.$route.query.page || 1,
        }
    },
    created() {
        this.tags.push(this.$route.params.name);
    },
    methods: {
      link_page(pageNum) {
        return this.$router.push({name : 'category', query : Object.assign({}, this.$route.query, { page : pageNum })});
      },
      remove_param(param) {
          let params = {...this.$route.params}
          delete params[param];
          if(Object.keys(params).length === 0) return this.$router.push({name : 'store'});
          else return this.$router.push({name : 'category', params : this.$route.param});
      },
      removeAllFilters() {
          return this.$router.push({name : 'store'});
      }
    }
}
</script>

